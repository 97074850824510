/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */

import React, { useEffect } from 'react';
import { usePaginatedFetch } from '../Tool/Api';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, CircularProgress } from '@material-ui/core';

const ObjectSelection = (props) => {
  const [open, setOpen] = React.useState(false);
  const { loading, load, items: options } = usePaginatedFetch(props.apiEndpoint)

  useEffect(() => { load() }, [])

  /*if (loading) return <CircularProgress />
  console.log("options", options)
  const filteredOptions = options//.filter(props.filterOptions || (() => true))

  console.log("filteredOptions", filteredOptions)*/

  const { filterOptions, ...cleanProps } = props

  return (<Autocomplete
    style={{ minWidth: 300 }}
    open={open}
    onOpen={() => {
      setOpen(true);
    }}
    onClose={() => {
      setOpen(false);
    }}
    getOptionSelected={(option, value) => option['@id'] === value['@id']}
    getOptionLabel={(option) => option.name}
    options={options.filter(filterOptions || (() => true))}
    loading={loading}
    fullWidth
    onInputChange={(e, search) => load(`${props.apiEndpoint}?search=${search}`)}
    renderInput={(params) => (
      <TextField
        {...params}
        fullWidth
        label={props.label}
        variant="outlined"
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
      />
    )}
    {...cleanProps}
  />)
}

export default ObjectSelection;