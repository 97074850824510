import ObjectSelection from '../Component/ObjectSelection';
import React from 'react';
import { Box } from '@material-ui/core';

const TicketFilters = ({ onChange, filters }) => {
  return (<Box my={1}><ObjectSelection
    apiEndpoint="/api/categories"
    label="Catégories"
    multiple
    size="small"
    onChange={(e, category) => onChange({ ...filters, category })}
    defaultValue={filters.category || []}
  /></Box>)
};

export default TicketFilters;