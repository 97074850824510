import clone from "clone";
/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */

import 'abortcontroller-polyfill';
import { useState, useCallback } from "react";

const AbortController = window.AbortController;

const AbortControllers = [];
const cache = {}
function memoize(method, force) {
  return async function () {
    let args = JSON.stringify(arguments);
    console.log('args', args);
    if (!cache[args] || force) {
      cache[args] = method.apply(this, arguments);
    }
    return cache[args];
  };
}

async function jsonLdFetch(url, method = 'GET', data = null) {

  const signifiantUrlPart = url.split('=')[0];
  if (AbortControllers[signifiantUrlPart]) {
    AbortControllers[signifiantUrlPart].abort();
  }
  AbortControllers[signifiantUrlPart] = new AbortController();
  const signal = AbortControllers[signifiantUrlPart].signal;
  const params = {
    signal,
    method,
    headers: {
      'Accept': "application/ld+json",
      'Content-Type': "application/ld+json"
    }
  }
  if (localStorage.getItem('switchUser')) {
    params.headers['X-Switch-User'] = localStorage.getItem('switchUser')
  }

  if (data) {
    params.body = JSON.stringify(data);
  }
  if (localStorage.getItem('API_TOKEN')) {
    params.headers.Authorization = 'Bearer ' + localStorage.getItem('API_TOKEN');
  }
  const response = await fetch(process.env.REACT_APP_API_ROOT + url, params)
  if (response.status === 204) {
    return false;
  }
  const ResponseData = await response.json()
  if (response.ok) {
    return ResponseData
  }
  else {
    throw ResponseData
  }
}

export function usePaginatedFetch(url) {
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [count, setCount] = useState(0)
  const [next, setNext] = useState(false)
  const [previous, setPrevious] = useState(false)
  const load = useCallback(async (urlOverride, forceRefresh = true) => {
    setLoading(true)
    try {
      const memoizedFetch = memoize(async function (url) { return jsonLdFetch(url) }, forceRefresh)
      const response = await memoizedFetch(urlOverride || url)
      setItems(items => response['hydra:member'])
      setCount(response['hydra:totalItems'])
      setLoading(false)
      setNext(response['hydra:view'] && response['hydra:view']['hydra:next'] ? response['hydra:view']['hydra:next'] : false)
      setPrevious(response['hydra:view'] && response['hydra:view']['hydra:previous'] ? response['hydra:view']['hydra:previous'] : false)
    }
    catch (error) {
      console.error(error)
      setLoading(false)
    }

  }, [url])

  return {
    load,
    loading,
    items,
    count,
    setItems,
    next,
    previous,
    loadNext: () => { if (next) { load(next) } },
    loadPrevious: () => { if (previous) { load(previous) } }
  }
}

export function useFetch(url, method = "POST", callback = null, errorCallback = null) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const load = useCallback(async (data = null, urlOverride = null, forceRefresh = true) => {
    setLoading(true)

    // On assainie les objets enfants en ne gardant que la référence @id au lieu de l'objet complet
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        if (value instanceof Object) {
          if (Array.isArray(value)) {
            value.forEach((subValue, subKey) => {
              if (subValue instanceof Object && subValue['@id']) {
                data[key][subKey] = subValue['@id']
              }
            });
          }
          else if (value['@id']) {
            data[key] = value['@id'];
          }
        }
      }
    }
    try {
      const memoizedFetch = memoize(async function (url, method, data) { return jsonLdFetch(url, method, data) }, forceRefresh)
      const response = await memoizedFetch(urlOverride || url, method, data)
      if (callback) {
        callback(response)
      }
      setLoading(false)
    }
    catch (error) {
      setErrors(error)
      if (errorCallback) {
        errorCallback(error);
      }
      setLoading(false)
    }

  }, [url, method, callback, errorCallback])

  return {
    load,
    loading,
    errors,
  }
}

export const usePostFile = (endCallback = null, errorCallback = null, url = "/api/media_objects") => {
  const [loading, setLoading] = useState({});
  const [loaded, setLoaded] = useState({});
  const [progress, setProgress] = useState({});

  const upload = (file, index) => {
    setLoading(loading => ({ ...loading, [index]: true }))
    setProgress(progress => ({ ...progress, [index]: 0 }))
    let data = new FormData();
    data.append('file', file);

    let request = new XMLHttpRequest();
    request.open('POST', process.env.REACT_APP_API_ROOT + url);
    request.setRequestHeader('Accept', 'application/ld+json');
    if (localStorage.getItem('switchUser')) {
      request.setRequestHeader('X-Switch-User', localStorage.getItem('switchUser'))
    }
    if (localStorage.getItem('API_TOKEN')) {
      request.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('API_TOKEN'));
    }

    request.upload.addEventListener('progress', (e) => {
      setProgress(progress => ({ ...progress, [index]: (e.loaded / e.total) * 100 }))
    })
    request.addEventListener('load', (e) => {
      if (request.status !== 200 && request.status !== 201) {
        if (errorCallback) {
          errorCallback(JSON.parse(request.response), index);
        }
        return;
      }
      setLoading(loading => ({ ...loading, [index]: false }))
      setLoaded(loaded => ({ ...loaded, [index]: true }));

      if (endCallback) {
        endCallback(JSON.parse(request.response), index);
      }
    });

    request.send(data)

  }
  return { loading, progress, upload, loaded }
}

export const useDownloadFile = () => {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const download = (url, loadingID) => {
    setLoading(loadingID);
    setLoaded(false);

    let request = new XMLHttpRequest();
    request.open('GET', process.env.REACT_APP_API_ROOT + url);
    request.responseType = 'blob';
    if (localStorage.getItem('switchUser')) {
      request.setRequestHeader('X-Switch-User', localStorage.getItem('switchUser'))
    }
    if (localStorage.getItem('API_TOKEN')) {
      request.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('API_TOKEN'));
    }
    request.addEventListener('load', (e) => {
      if (request.status !== 200) {
        setLoading(false);
        return;
      }
      setLoading(false);
      setLoaded(true);
      const url = URL.createObjectURL(request.response);
      const link = document.createElement('a');
      link.href = url;
      link.download = `ticket_${loadingID}.pdf`;
      document.body.appendChild(link);
      console.log(link)
      link.click();
      document.body.removeChild(link);
    });
    request.send();
  }
  return { loading, download, loaded }
}