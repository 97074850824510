/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */

import { createContext, useContext, useCallback } from "react";
import { useFetch } from "../Tool/Api";

export const LOGIN = "login";
export const LOGOUT = "logout";
export const LOADING = "loading";
export const LOADED = "loaded";
export const SET = "set";
export const UPDATE = "update";

export const initialState = {
  loggedin: false,
  loaded: false,
  user: null,
  switchUser: false,
}

//Reducer
export const BackendReducer = (state, action) => {
  switch (action.action) {
    case LOGIN:
      localStorage.setItem('API_TOKEN', action.token);
      return { ...state, loggedin: true }
    case LOGOUT:
      localStorage.removeItem('API_TOKEN');
      localStorage.removeItem('switchUser');
      return { ...state, loggedin: false, user: null };
    case LOADING:
      return { ...state, loading: true }
    case LOADED:
      return { ...state, loading: false }
    case SET:
      return { ...action.value }
    case UPDATE:
      const newState = { ...state }
      newState[action.key] = action.value;
      return newState
    default:
      return state;
  }
}

export const useCurrentUser = () => {
  const { backend, backendDispatch } = useContext(BackendContext)
  const { load } = useFetch('/api/users/me', 'GET', (datas) => {
    localStorage.setItem('theme', datas.instance?.theme)
    console.log("Dispatch user !")
    backendDispatch({ action: SET, value: { ...backend, user: datas, loaded: true, loggedin: true } })
  }, error => {
    backendDispatch({ action: UPDATE, value: true, key: 'loaded' })
  })
  return { load, currentUser: backend.user }
}

export const useCan = () => {
  const { currentUser } = useCurrentUser()
  const can = useCallback((cap, obj = null) => {
    // @TODO : Implémenter cette fonction de vérification des autorisations
    switch (cap) {
      case 'SUPER_ADMIN':
        return currentUser.isAdmin || false;
      case 'TICKET_EDIT':
        const intersect_edit = obj?.ticket?.entities?.filter(v => currentUser?.entities?.map(e => e['@id']).includes(v['@id']));

        // Condition for EDIT_ALL Capability. 
        const condAll = (
          (can("TICKET_EDIT_ALL") && !obj.ticket) // Edit any ticket 
          || (can("TICKET_EDIT_ALL") && !currentUser.capcats?.TICKET_EDIT_ALL) // Edit a specific ticket but user is allowed to edit any category of ticket
          || (can("TICKET_EDIT_ALL") && currentUser.capcats.TICKET_EDIT_ALL.includes(obj.ticket.category.id)) // Edit a specific ticket only if user is allowed to edit this category of ticket
        )

        // Condition for EDIT_OWN Capability.
        const condOwn = (
          (can("TICKET_EDIT_OWN") && obj?.ticket?.author?.['@id'] === currentUser?.['@id'] && !currentUser.capcats?.TICKET_EDIT_OWN) // Edit a specific ticket but user is allowed to edit any category of ticket
          || (can("TICKET_EDIT_OWN") && obj?.ticket?.author?.['@id'] === currentUser?.['@id'] && currentUser.capcats.TICKET_EDIT_OWN.includes(obj.ticket.category.id)) // Edit a specific ticket only if user is allowed to edit this category of ticket
        )

        // Condition for EDIT_ENTITY Capability.
        const condEntity = (
          (can("TICKET_EDIT_ENTITY") && intersect_edit.length > 0 && !currentUser.capcats?.TICKET_EDIT_ENTITY) // Edit a specific ticket but user is allowed to edit any category of ticket
          || (can("TICKET_EDIT_ENTITY") && intersect_edit.length > 0 && currentUser.capcats.TICKET_EDIT_ENTITY.includes(obj.ticket.category.id)) // Edit a specific ticket only if user is allowed to edit this category of ticket
        )

        return condAll || condOwn || condEntity;
      case 'TICKET_DELETE':
        const intersect_delete = obj?.ticket?.entities?.filter(v => currentUser?.entities?.map(e => e['@id']).includes(v['@id']));

        // Condition for DELETE_ALL Capability.
        const condAllDelete = (
          (can("TICKET_DELETE_ALL") && !obj.ticket) // Delete any ticket
          || (can("TICKET_DELETE_ALL") && !currentUser.capcats?.TICKET_DELETE_ALL) // Delete a specific ticket but user is allowed to delete any category of ticket
          || (can("TICKET_DELETE_ALL") && currentUser.capcats.TICKET_DELETE_ALL.includes(obj.ticket.category.id)) // Delete a specific ticket only if user is allowed to delete this category of ticket
        )

        // Condition for DELETE_OWN Capability.
        const condOwnDelete = (
          (can("TICKET_DELETE_OWN") && obj?.ticket?.author?.['@id'] === currentUser?.['@id'] && !currentUser.capcats?.TICKET_DELETE_OWN) // Delete a specific ticket but user is allowed to delete any category of ticket
          || (can("TICKET_DELETE_OWN") && obj?.ticket?.author?.['@id'] === currentUser?.['@id'] && currentUser.capcats.TICKET_DELETE_OWN.includes(obj.ticket.category.id)) // Delete a specific ticket only if user is allowed to delete this category of ticket
        )

        // Condition for DELETE_ENTITY Capability.
        const condEntityDelete = (
          (can("TICKET_DELETE_ENTITY") && intersect_delete.length > 0 && !currentUser.capcats?.TICKET_DELETE_ENTITY) // Delete a specific ticket but user is allowed to delete any category of ticket
          || (can("TICKET_DELETE_ENTITY") && intersect_delete.length > 0 && currentUser.capcats.TICKET_DELETE_ENTITY.includes(obj.ticket.category.id)) // Delete a specific ticket only if user is allowed to delete this category of ticket
        )

        return condAllDelete || condOwnDelete || condEntityDelete;
      case 'TICKET_SELECT_CAT':
        return (can("TICKET_ADD") && !obj?.category) || (can("TICKET_ADD") && !currentUser.capcats?.TICKET_ADD) || (can("TICKET_ADD") && currentUser.capcats?.TICKET_ADD?.includes(obj?.category.id))

      default:
        return currentUser.capabilities.indexOf(cap) > -1 || currentUser.isAdmin
    }
  }, [currentUser])
  return { can }
}

// Context
const BackendContext = createContext({
  backend: {},
  backendDispatch: ({ }) => { }
});
export default BackendContext;