/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import React, { useEffect } from 'react';
import { TextField, Box, Checkbox, Tooltip, Typography, FormControl, FormLabel, FormGroup, FormControlLabel } from '@material-ui/core';
import ObjectSelection from '../Component/ObjectSelection';
import { useCan } from '../Context/BackendContext';
import { usePaginatedFetch } from '../Tool/Api';
import clone from 'clone';
import { BlockPicker } from 'react-color';
import MyColorField from '../FormBuilder/MyColorField';
import MyCheckbox from '../FormBuilder/MyCheckbox';

const SatusForm = (props) => {
  const { can } = useCan();

  return (<>
    <TextField fullWidth label="Nom du statut" value={props.entity.name} onChange={v => { props.setEntity({ ...props.entity, name: v.currentTarget.value }) }} variant="outlined" />
    {can("SUPER_ADMIN") ?
      <Box mt={2}>
        <ObjectSelection
          apiEndpoint="/api/instances"
          label="Instance"
          defaultValue={props.entity.instance}
          onChange={(e, instance) => { props.setEntity({ ...props.entity, instance }) }}
        />
      </Box> : null}
    <Box mt={2}>
      <MyCheckbox label="Définir comme statut par défaut" value={props.entity.isDefault} onChange={(v) => { props.setEntity({ ...props.entity, isDefault: v.currentTarget.checked }) }} />
    </Box>
    <Box mt={2}>
      <MyColorField label="Couleur" value={props.entity.color} onChange={(color, e) => { props.setEntity({ ...props.entity, color: color.hex }) }} />
    </Box>
  </>)
}

export default SatusForm;