/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */

import { TextField } from "@material-ui/core"
import MyAutocomplete from './MyAutocomplete';
import clone from 'clone';
import MyCheckboxes from './MyCheckboxes';
import MyCheckbox from './MyCheckbox';
import MyRadios from './MyRadios';
import MyFileUpload from './MyFileUpload';
import MyGPSField from './MyGPSField';
import { KeyboardTimePicker, KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import MyNumber from "./MyNumber";
import MyTitle from "./MyTitle";
import { SignatureField } from "./Signature";

const optionsSubField = {
  name: 'options',
  label: 'Options disponibles',
  component: 'textField',
  multiline: true,
  rows: 4,
  helperText: "Une seule option par ligne",
  required: true,
  onLoadValue: (v) => v?.join('\n') || '',
  onChangeValue: (e) => e.currentTarget.value.split('\n')
}

export const formComponents = [
  {
    name: 'title',
    component: MyTitle,
    editorLabel: 'Titre',
    editorFields: [],
    defaultProps: {
      variant: "outlined",
      fullWidth: true
    }
  },
  {
    name: 'textField',
    component: TextField,
    editorLabel: 'Champ de texte',
    editorFields: [],
    defaultProps: {
      variant: "outlined",
      fullWidth: true
    }
  },
  {
    name: 'number',
    component: MyNumber,
    editorLabel: 'Nombre',
    editorFields: [
      {
        name: "min",
        label: "Minimum",
        component: 'number',
        helperText: "Valeur minimum que l'utilisateur pourra saisir. Laissez vide pour ne pas limiter"
      },
      {
        name: "max",
        label: "Maximum",
        component: 'number',
        helperText: "Valeur maximum que l'utilisateur pourra saisir. Laissez vide pour ne pas limiter"
      },
      {
        name: "step",
        label: "Pas",
        component: 'number',
        helperText: "Par exemple '5' pour n'autoriser que des multiples de 5 ou '0.01' pour autoriser des nombres à deux chiffre après la virgule. Laissez vide pour autoriser un format libre"
      },
    ],
    defaultProps: {
      variant: "outlined",
      fullWidth: true,
      type: "number",
    }
  },
  {
    name: 'textArea',
    component: TextField,
    editorLabel: 'Champ de texte multiligne',
    editorFields: [],
    defaultProps: {
      variant: "outlined",
      fullWidth: true,
      multiline: true,
      rows: 10
    }
  },
  {
    name: 'checkbox',
    component: MyCheckbox,
    editorLabel: 'Case à cocher',
    editorFields: [],
    onChangeValue: (e) => e.currentTarget.checked
  },
  {
    name: 'checkboxes',
    component: MyCheckboxes,
    editorLabel: 'Choix multiples',
    editorFields: [clone(optionsSubField)],
    onChangeValue: (v) => v
  },
  {
    name: 'radios',
    component: MyRadios,
    editorLabel: 'Choix unique',
    editorFields: [clone(optionsSubField)]
  },
  {
    name: 'select',
    component: MyAutocomplete,
    editorLabel: "Liste déroulante",
    editorFields: [clone(optionsSubField)],
    onChangeValue: (e, v) => v,
    defaultProps: {
      options: [],
    }
  },
  {
    name: 'date',
    component: KeyboardDatePicker,
    editorLabel: "Date",
    editorFields: [],
    onChangeValue: (v) => v,
    defaultProps: {
      format: 'dd/MM/yyyy',
      cancelLabel: 'Annuler',
      okLabel: 'Valider'
    }
  },
  {
    name: 'time',
    component: KeyboardTimePicker,
    editorLabel: "Heure",
    editorFields: [],
    onChangeValue: (v) => v,
    defaultProps: {
      ampm: false, // To use 24h format
      cancelLabel: 'Annuler',
      okLabel: 'Valider'
    }
  },
  {
    name: 'datetime',
    component: KeyboardDateTimePicker,
    editorLabel: "Date et Heure",
    editorFields: [],
    onChangeValue: (v) => v,
    defaultProps: {
      format: 'dd/MM/yyyy HH:mm',
      ampm: false, // To use 24h format
      cancelLabel: 'Annuler',
      okLabel: 'Valider'
    }
  },
  {
    name: 'file',
    component: MyFileUpload,
    editorLabel: "Fichier / Photo / Vidéo",
    onChangeValue: (v) => v,
    editorFields: [],
    defaultProps: {}
  },
  {
    name: 'gps',
    component: MyGPSField,
    editorLabel: "Coordonnées GPS",
    editorFields: [{
      name: "buttonLabel",
      label: "Intitulé du bouton",
      component: 'textField',
    }],
    onChangeValue: (v) => v,
    defaultProps: {}
  },
  {
    name: 'signature',
    component: SignatureField,
    editorLabel: "Signature bout du doigt",
    editorFields: [],
    onChangeValue: (v) => v,
    defaultProps: {}
  }
]

export const emptyField = {
  name: null,
  label: '',
  helperText: '',
  component: 'textField',
  required: false,
  conditionnal: false,
  conditions: [],
}

export const comparisons = [
  {
    name: 'equal',
    label: "Est égal à",
    allowValue: true,
    compare: (a, b) => JSON.stringify(a) === JSON.stringify(b)
  },
  {
    name: 'notEqual',
    label: "Est différent de",
    allowValue: true,
    compare: (a, b) => JSON.stringify(a) !== JSON.stringify(b)
  },
  {
    name: 'yes',
    label: "Est vrai/coché",
    allowValue: false,
    compare: (a, b) => Boolean(a)
  },
  {
    name: 'no',
    label: "Est faux/non coché",
    allowValue: false,
    compare: (a, b) => !Boolean(a)
  },
]