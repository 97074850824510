/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import React, { useRef } from 'react';
import { Container, Typography, Grid, IconButton, Box, Tooltip, TableCell, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { StatusesTable } from '../Tables/StatusesTable';


import EditIcon from '@material-ui/icons/Edit';
import clone from 'clone';
import ConfirmButton from '../Component/ConfirmButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { useCan } from '../Context/BackendContext';
import ListPage from '../Component/ListPage';
import StatusForm from '../Form/StatusForm';
import AdjustIcon from '@material-ui/icons/Adjust';

const emptyStatus = {
  name: null,
  capabilities: []
}

const StatusPage = React.memo((props) => {
  // Constantes
  const ListPageRef = useRef()
  const { can } = useCan();

  // Colones du tableau
  // -- Instance
  if (can('SUPER_ADMIN')) {
    StatusesTable.instance = {
      head: <TableCell align="right">Instance</TableCell>,
      row: (row) => <TableCell align='left'>{row.instance.name}</TableCell>
    }
  }
  // -- Actions
  StatusesTable.actions = {
    head: <TableCell align="right">Actions</TableCell>,
    row: (row) => <TableCell align="right">
      <Tooltip title="Modifier le Statut"><IconButton onClick={() => { ListPageRef.current.setEntity(clone(row)); ListPageRef.current.setDial(true) }}><EditIcon /></IconButton></Tooltip>
      <ConfirmButton title="Supprimer ce Statut" button={<IconButton><DeleteIcon /></IconButton>} onConfirm={() => ListPageRef.current.deleteEntity(row)} >Voulez-vous vraiment supprimer ce Statuse de façon définitive ?</ConfirmButton>
    </TableCell >
  }

  //Création du bouton d'ajout
  const addButton = <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => ListPageRef.current.setDial(true)}>Ajouter un Statut</Button>

  return (<Container>
    <Grid container justifyContent="space-between" spacing={2}>
      <Grid item><Typography variant="h1"><AdjustIcon /> Statuts des tickets</Typography></Grid>
      <Grid item>{addButton}</Grid>
    </Grid>

    <Box mt={4}>
      <ListPage
        apiEndpoint="/api/statuses"
        emptyEntity={emptyStatus}
        entityTable={StatusesTable}
        addButton={addButton}
        editForm={StatusForm}
        ref={ListPageRef}
      />
    </Box>

  </Container>)
})

export default StatusPage;