import React, { Fragment, useEffect, useState, useContext } from 'react';
import './App.css';
import LoginPage from './Page/LoginPage';
import { SnackbarProvider } from 'notistack';
import BackendContext, { useCurrentUser } from './Context/BackendContext';
import { CircularProgress, Typography, Box } from '@material-ui/core';
import MyAppBar from './Component/AppBar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AppRoutes from './Component/AppRoutes';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";


function App() {
  const { backend } = useContext(BackendContext)
  const { load: loadUser } = useCurrentUser();
  const [retry, setRetry] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('API_TOKEN') && !retry && !backend.loaded && !backend.user) {
      console.log("Load user !")
      loadUser();
      setRetry(true)
    }
  }, [retry, backend, loadUser, setRetry])

  return (<MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}><SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}>
    {
      backend.loading ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
          <CircularProgress color="primary" /><br />
          <Typography variant="h3">Chargement ...</Typography>
        </div>
        : backend.loggedin ?
          <Fragment>
            <Router>
              <MyAppBar />
              <Switch>
                {AppRoutes.map((r, k) => (
                  <Route exact path={r.path} key={k} >
                    <Box my={4}>{r.content}</Box>
                  </Route>)
                )}
              </Switch>
            </Router>
          </Fragment>
          :
          <LoginPage />
    }
  </SnackbarProvider></MuiPickersUtilsProvider>);
}

export default App;
