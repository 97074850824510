import { IconButton, Menu } from '@material-ui/core';
import React from 'react';
import TuneIcon from '@material-ui/icons/Tune';

const Filters = React.memo(({ onChange, filters, filtersComponent }) => {
  /**
   * Define needed states
   */
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  return React.createElement(filtersComponent, { onChange, filters })
  return (<>
    <IconButton onClick={() => setOpen(!open)} ref={anchorRef} ><TuneIcon /></IconButton>
    <Menu open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
    </Menu>
  </>)
})

export default Filters