/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import React from 'react';
import { TextField, Box, Divider, Typography, FormControl, FormControlLabel, FormLabel, FormGroup, Checkbox, } from '@material-ui/core';
import ObjectSelection from '../Component/ObjectSelection';
import { useCan } from '../Context/BackendContext';
import FormBuilder from '../FormBuilder/FormBuilder';
import MyCheckboxes from '../FormBuilder/MyCheckboxes';



const CategoryForm = (props) => {
  const { can } = useCan();


  return (<>
    <TextField fullWidth label="Nom de la catégorie" value={props.entity.name} onChange={v => { props.setEntity({ ...props.entity, name: v.currentTarget.value }) }} variant="outlined" />
    <Box mt={2}><TextField fullWidth label="Référence interne" value={props.entity.ref} onChange={v => { props.setEntity({ ...props.entity, ref: v.currentTarget.value }) }} variant="outlined" /></Box>
    {can("SUPER_ADMIN") ?
      <Box my={2}>
        <ObjectSelection
          apiEndpoint="/api/instances"
          label="Instance"
          defaultValue={props.entity.instance}
          onChange={(e, instance) => { props.setEntity({ ...props.entity, instance }) }}
        />
      </Box> : null}
    <Box my={2}>
      <FormControl component="fieldset">
        <FormLabel component="legent">Visibilité de cette catégorie</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox label="Cette catégorie est activée" checked={props.entity.visible} onChange={e => { props.setEntity({ ...props.entity, visible: e.currentTarget.checked }) }} />}
            label="Cette catégorie est activée"
          />
        </FormGroup>
      </FormControl>
    </Box>
    < Divider />
    <Box my={2}><Typography variant="h3">Formulaire de ticket pour cette catégorie</Typography></Box>
    <FormBuilder {...props} />
    < Divider />
  </>)
}

export default CategoryForm;