/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import { createStyles, makeStyles, Tooltip, Typography } from '@material-ui/core';
import React from 'react';

const useStyle = makeStyles(theme => createStyles(
  {
    badge: props => ({
      borderRadius: 2000,
      width: theme.spacing(1),
      height: theme.spacing(1),
      margin: theme.spacing(1),
      backgroundColor: props.color,
    }),
  }
));

const Badge = (props) => {
  const classes = useStyle(props);
  return (<Tooltip title={props.text}><div className={[classes.badge]} /></Tooltip>)
}

export default Badge;