/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import React from 'react';
import { Box } from "@material-ui/core";
import FieldRender from './FieldRender';
import { comparisons } from './Fields';
import clone from 'clone';
import { useCurrentUser } from '../Context/BackendContext';

const FormRender = (props) => {
  const { currentUser } = useCurrentUser();

  const mustShow = (field) => {
    if (!field.conditionnal && !field.enableAuthorizations) {
      return true
    }

    let show = true;
    if (field.conditionnal) {
      field.conditions.forEach(c => {
        let subShow = false;
        c.forEach(sc => {
          const currentComparison = clone(comparisons)[comparisons.findIndex(el => el.name === sc.comparison)];
          const comparedFieldValue = clone(props.entity.datas)[sc.field]
          console.log('comparedFieldValue', comparedFieldValue);
          console.log('sc', sc);
          if (currentComparison.compare(comparedFieldValue, sc.value)) {
            subShow = true;
          }
        })
        if (!subShow) {
          show = false;
        }
      })
    }

    if (!show)
      return show;

    if (field.enableAuthorizations) {
      show = false;
      currentUser.groups.map(g => {
        if (field.authorizations?.[g.id]?.indexOf("SHOW") > -1) {
          show = "readonly"
        }
        if (show === "readonly" && field.authorizations?.[g.id]?.indexOf("EDIT") > -1) {
          show = true;
        }
      })
    }

    return show
  }
  return (<>
    {props.form?.fields?.map(f => {
      const ms = mustShow(f); return mustShow(f) ? (<Box mt={2}>
        <FieldRender
          {...f}
          value={props.entity.datas?.[f.name]}
          onChange={value => props.onChange(f.name, value)}
          setLock={props.setLock}
          disabled={ms === 'readonly'}
        />
      </Box>) : null
    })}
  </>)
}

export default FormRender;