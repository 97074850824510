/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */

import React, { Fragment, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Tooltip } from '@material-ui/core';

const ConfirmButton = (props) => {
  const [showAlert, setShowAlert] = useState(false)
  const button = React.cloneElement(props.button, { onClick: () => setShowAlert(true) })
  return (<Fragment>
    {props.title ? <Tooltip title={props.title}>{button}</Tooltip> : { button }}
    <Dialog open={showAlert}>
      {props.title ? <DialogTitle>{props.title}</DialogTitle> : null}
      <DialogContent>
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => { setShowAlert(false) }}>{props.cancelLabel || 'Non'}</Button>
        <Button variant="contained" color="primary" onClick={() => { setShowAlert(false); props.onConfirm() }} >{props.confirmLabel || 'Oui'}</Button>
      </DialogActions>
    </Dialog>
  </Fragment>)
}

export default ConfirmButton;