/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */

/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import React from 'react';
import clone from 'clone';
import { FormControl, FormControlLabel, FormGroup, FormLabel, Radio } from '@material-ui/core';

const MyRadios = (props) => {
  const componentProps = clone(props)
  const options = props.options
  delete componentProps.options
  delete componentProps.component

  return (<FormControl component="fieldset">
    <FormLabel component="legent">{props.label}</FormLabel>
    <FormGroup>
      {options.map(o => <FormControlLabel
        key={o}
        control={<Radio label={o} value={o} checked={props.value === o} onChange={props.onChange} />}
        label={o}
      />)}
    </FormGroup>
  </FormControl>)

}

export default MyRadios;