import { Button, createStyles, FormControl, FormLabel, InputBase, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas'

const useStyle = makeStyles(theme => createStyles({
  root: {
    borderRadius: theme.shape.borderRadius,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    position: 'relative',
  },
  label: {
    display: 'inline-block',
    position: 'absolute',
    top: -8,
    left: 8,
    background: '#fff',
    padding: '0 5px',
    fontSize: '0.8rem',
  }
}));


export const SignatureField = (props) => {
  const classes = useStyle();
  const canvasRef = React.useRef(null);

  useEffect(() => {
    if (props.value) {
      canvasRef.current.fromDataURL(props.value);
    }
  }, [props.value])

  const onEnd = (e) => {
    props.onChange(canvasRef.current.toDataURL());
  }
  const clear = () => {
    canvasRef.current.clear();
    props.onChange(null);
  }

  return <FormControl component="fieldset" variant='outlined' className={classes.root} fullWidth>
    <FormLabel component="legent" className={classes.label}>{props.label}</FormLabel>
    <div>
      <SignatureCanvas canvasProps={{ style: { width: '100%' }, height: 250 }} backgroundColor="#eee" onEnd={onEnd} ref={canvasRef} />
      {props.value && <Button variant="contained" onClick={clear}>Effacer la signature</Button>}
    </div>
  </FormControl>
}