/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import React from 'react';
import { Box, TextField } from '@material-ui/core';
import themes from '../Theme/index'
import Autocomplete from '@material-ui/lab/Autocomplete';
import clone from 'clone';


const InstanceForm = (props) => {
  const numberings = [
    { value: 'simple', label: 'Simple (ex: 123)' },
    { value: 'year', label: `Année (ex: ${(new Date()).getFullYear()}-123)` },
    { value: 'month', label: `Mois (ex: ${(new Date()).getFullYear()}-01-123)` },
  ]
  const selectedNumbering = numberings.find(n => n.value === props.entity.numbering) || numberings[0]
  const selectectedTheme = clone(themes)[themes.findIndex(el => el.name === props.entity.theme)];
  return (<>
    <Box mt={2}><TextField fullWidth label="Nom du l'instance" value={props.entity.name} onChange={v => { props.setEntity({ ...props.entity, name: v.currentTarget.value }) }} variant="outlined" /></Box>
    <Box mt={2}><Autocomplete
      label="Style de numérotation des tickets"
      options={numberings}
      getOptionLabel={o => o.label}
      getOptionSelected={(o, v) => { return o.value === v.value }}
      value={selectedNumbering}
      onChange={(e, v) => { props.setEntity({ ...props.entity, numbering: v ? v.value : 'simple' }) }}
      variant="outlined"
      required
      fullWidth
      renderInput={(params) => <TextField {...params} label="Style de numérotation des tickets" variant="outlined" />}
    /></Box>
    <Box mt={2}><TextField fullWidth label="Prochain numéro de ticket" type='number' value={props.entity.increment} onChange={v => { props.setEntity({ ...props.entity, increment: v.currentTarget.value }) }} variant="outlined" /></Box>
    <Box mt={2}><TextField fullWidth label="URL du fichier Google Sheet" helperText="Si vous n'en avez pas encore, créez une feuille de calcul dans Google Drive et copiez-collez l'URL de ce document ici. Il ne s'agit pas de l'URL de partage mais bien de l'URL lorsque l'on édite ce document" value={props.entity.googlesheet} onChange={v => { props.setEntity({ ...props.entity, googlesheet: v.currentTarget.value }) }} variant="outlined" /></Box>
    <Box mt={2}><Autocomplete
      label="Theme à utiliser"
      options={themes}
      getOptionLabel={o => o.label}
      getOptionSelected={(o, v) => { return o.name === v.name }}
      value={selectectedTheme || null}
      onChange={(e, v) => { props.setEntity({ ...props.entity, theme: v ? v.name : 'default' }) }}
      variant="outlined"
      required
      fullWidth
      renderInput={(params) => <TextField {...params} label="Theme à utiliser" variant="outlined" />}
    /></Box>
    <Box mt={2}><TextField fullWidth label="URL de la page d'accueil wordpress" helperText="Si ce champ est définit, on essaye d'accéder à l'API wordpress pour récupérer la liste des utilisateurs et leur permettre de s'identifier avec leur mot de passe wordpress" value={props.entity.wordpress} onChange={v => { props.setEntity({ ...props.entity, wordpress: v.currentTarget.value }) }} variant="outlined" /></Box>
    <Box mt={2}><TextField fullWidth label="Token d'API OAuth " value={props.entity.apikey} variant="outlined" InputProps={{ readOnly: true }} /></Box>
  </>)
}

export default InstanceForm;