/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import React from 'react';
import clone from 'clone';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const MyCheckbox = (props) => {
  const componentProps = clone(props)
  delete componentProps.component

  return (<>
    <FormControlLabel
      control={<Checkbox {...props} name={props.label} checked={Boolean(props.value)} />}
      label={props.label}
    />
  </>)

}

export default MyCheckbox;