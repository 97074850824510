/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */

import React, { Fragment } from 'react';
import { TableCell, Tooltip } from "@material-ui/core";
import Label from '../Component/Label';




export const TicketsTable = {
  'id': {
    head: <TableCell align='left'>#</TableCell>,
    row: (row) => <TableCell align='left'>{row.ref}</TableCell>
  },
  'category': {
    head: <TableCell align='left'>Catégorie</TableCell>,
    row: (row) => <TableCell align='left'>{row.category.name}</TableCell>
  },
  'date': {
    head: <TableCell align='left'>Mise à jour</TableCell>,
    row: (row) => <TableCell align='left'><Tooltip title={<Fragment>Création : {(new Date(row.created)).toLocaleString()}<br />Mise à jour : {(new Date(row.updated)).toLocaleString()}</Fragment>}><abbr>{(new Date(row.updated)).toLocaleDateString()}</abbr></Tooltip></TableCell>
  },
  'author': {
    head: <TableCell align='left'>Auteur</TableCell>,
    row: (row) => <TableCell align='left'><Tooltip title={<Fragment>Groupe(s) : {row.author?.groups?.map?.(e => e.name).join?.(', ')}<br />Entité(s) : {row.author?.entities?.map(e => e.name).join?.(', ')}<br />Instance : {row.instance?.name}</Fragment>}><abbr>{row.author?.firstName} {row.author?.lastName}</abbr></Tooltip></TableCell>
  },
  'status': {
    head: <TableCell>Statut</TableCell>,
    cap: "TICKET_SHOW_STATUS",
    row: (row) => <TableCell><Label color={row.status.color} text={row.status.name} /></TableCell>
  }
}