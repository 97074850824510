/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import React, { useRef } from 'react';
import { Container, Typography, Grid, IconButton, Box, Tooltip, TableCell, Button, useMediaQuery } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { TicketsTable } from '../Tables/TicketsTable';


import EditIcon from '@material-ui/icons/Edit';
import clone from 'clone';
import ConfirmButton from '../Component/ConfirmButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { useCan } from '../Context/BackendContext';
import ListPage from '../Component/ListPage';
import TicketForm from '../Form/TicketForm';
import Label from '../Component/Label';
import CommentIcon from '@material-ui/icons/Comment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DescriptionIcon from '@material-ui/icons/Description';
import { useDownloadFile, useFetch } from '../Tool/Api';
import TicketFilters from '../Filters/TicketFilters';
import { useTheme } from '@material-ui/styles';

const emptyTicket = {
  name: null,
  datas: {}
}

const TicketPage = React.memo((props) => {
  // Constantes
  const ListPageRef = useRef()
  const { can } = useCan();
  const { download: downloadPDF, loading: loadingPDF } = useDownloadFile();
  const theme = useTheme();
  const isWeb = useMediaQuery(theme.breakpoints.up('md'));

  // Colones du tableau
  // -- Instance
  if (can('SUPER_ADMIN')) {
    TicketsTable.instance = {
      head: <TableCell align="right">Instance</TableCell>,
      row: (row) => <TableCell align='left'>{row.instance.name}</TableCell>
    }
  }
  // -- Entities
  if (can('TICKET_SET_ENTITIES') || can('TICKET_SHOW_ALL') || can('TICKET_SHOW_ENTITY')) {
    TicketsTable.entities = {
      head: <TableCell align="right">Entité(s)</TableCell>,
      row: (row) => <TableCell align='left'>{row.entities.map((entity, key) => <Label key={key} text={entity.name} color="#d7d7d7" />)}</TableCell>
    }
  }
  // -- Actions
  TicketsTable.actions = {
    head: <TableCell align="right">Actions</TableCell>,
    row: (row) => <TableCell align="right">
      {isWeb && <Tooltip title="Télécharger les détails au format PDF"><IconButton disabled={loadingPDF === row.id} onClick={() => downloadPDF(`/api/tickets/${row.id}/pdf`, row.id)}><DescriptionIcon /></IconButton></Tooltip>}
      {can("TICKET_EDIT", { ticket: row }) ?
        <Tooltip title="Modifier le ticket"><IconButton onClick={() => { ListPageRef.current.setEntity(clone(row)); ListPageRef.current.setDial(true); ListPageRef.current.setLock({}) }}><EditIcon /></IconButton></Tooltip> :
        <Tooltip title="Voir le ticket"><IconButton onClick={() => { ListPageRef.current.setEntity(clone(row)); ListPageRef.current.setDial(true); ListPageRef.current.setLock({ all: true }) }}><VisibilityIcon /></IconButton></Tooltip>
      }
      {
        can("TICKET_DELETE", { ticket: row }) ?
          <ConfirmButton title="Supprimer ce ticket" button={<IconButton><DeleteIcon /></IconButton>} onConfirm={() => ListPageRef.current.deleteEntity(row)} >Voulez-vous vraiment supprimer ce ticket de façon définitive ?</ConfirmButton> : null
      }
    </TableCell >
  }

  //Création du bouton d'ajout
  const addButton = can("TICKET_ADD") ? <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => ListPageRef.current.setDial(true)}>Ajouter un ticket</Button> : null

  return (<Container>
    <Grid container justifyContent="space-between" spacing={2}>
      <Grid item><Typography variant="h1"><CommentIcon /> Tickets</Typography></Grid>
      <Grid item>{addButton}</Grid>
    </Grid>

    <Box mt={4}>
      <ListPage
        apiEndpoint="/api/tickets"
        emptyEntity={emptyTicket}
        entityTable={TicketsTable}
        addButton={addButton}
        editForm={TicketForm}
        filters={TicketFilters}
        ref={ListPageRef}
      />
    </Box>

  </Container>)
})

export default TicketPage;