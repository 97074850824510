/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import React, { useEffect } from 'react';
import { usePaginatedFetch } from '../Tool/Api';
import PersonIcon from '@material-ui/icons/Person';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AddIcon from '@material-ui/icons/Add';
import { Container, Typography, createStyles, makeStyles, Box, Card, CardContent, Grid, CardActions, Button, CardHeader, Tooltip, IconButton } from "@material-ui/core";
import { Link } from 'react-router-dom';
import Badge from '../Component/Badge';
import Label from '../Component/Label';
import { useCan } from '../Context/BackendContext';

const useStyle = makeStyles(theme => createStyles({
  bigTitle: {
    fontSize: theme.typography.pxToRem(64),
    fontWeight: theme.typography.fontWeightLight
  },
}))

const DashBoardPage = (props) => {
  const classes = useStyle()
  const { items, count, loading, load } = usePaginatedFetch("/api/tickets?itemsPerPage=4");
  const { can } = useCan();

  useEffect(() => { load() }, []);
  let ret = null;
  if (loading) {
    ret = <Box p={4} textAlign="center"><Typography variant="h3">Chargement ...</Typography></Box>
  }
  if (!count) {
    ret = <Box p={4} textAlign="center"><Typography variant="h3">Aucun résultat à afficher</Typography><br /></Box>
  }

  if (count) {
    ret = <>
      <Box my={2}><Grid container justifyContent="space-between">
        <Grid item ><Typography variant="h2">Vos derniers tickets </Typography></Grid>
        {/*<Grid item><Button size="small" startIcon={<AddIcon />} >Nouveau</Button></Grid> */}
      </Grid></Box>
      <Grid container spacing={2}>{items.map(item => <Grid item xs={12} sm={6} md={4} lg={3}><Card>
        <CardHeader title={'#' + item.ref} subheader={item.category?.name} action={() => { if (can("TICKET_SHOW_STATUS")) { return (<Label color={item.status.color} text={item.status.name} />) } }} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item><Typography variant="body1"><AccessTimeIcon style={{ verticalAlign: 'middle' }} /> {(new Date(item.created)).toLocaleDateString()}</Typography></Grid>
            {item.author?.firstName || item.author?.lastName ? <Grid item><Typography variant="body1"><PersonIcon style={{ verticalAlign: 'middle' }} /> {item.author.firstName || ''} {item.author.lastName || ''}</Typography></Grid> : null}
          </Grid>
        </CardContent>
        <CardActions >
          {/*<Button size="small">Détails</Button>*/}
        </CardActions>
      </Card></Grid>)
      }</Grid >
      <Box my={2} textAlign="center"><Button variant="contained" color="primary" component={Link} to="/ticket">Voir tous les tickets</Button></Box></>
  }

  return (<Container>
    <Typography className={classes.bigTitle}>Bienvenue !</Typography>
    {ret}

  </Container>)
}

export default DashBoardPage;
