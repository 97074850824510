/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import { createTheme } from '@material-ui/core';
import { frFR } from '@material-ui/core/locale';

export const defaultTheme = {
  typography: {
    h1: {
      fontSize: "2rem"
    },
    h2: {
      fontSize: "1.6rem"
    },
    h3: {
      fontSize: "1.4rem"
    },
    h4: {
      fontSize: "1.2rem"
    },
    h5: {
      fontSize: "1.1rem"
    },
    h6: {
      fontSize: "1rem"
    },
  }
}

const theme = createTheme(defaultTheme, frFR);

export default theme;
