/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */

import React, { useContext } from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, IconButton, Menu, MenuItem, MenuList, Box, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import BackendContext, { LOGOUT, useCan } from '../Context/BackendContext';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppRoutes from '../Component/AppRoutes';
import { Link } from 'react-router-dom';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';

//router link

const drawerWidth = 240;

const useStyle = makeStyles(theme => createStyles(
  {
    title: {
      flexGrow: 1
    },
    link: {
      color: "inherit",
      textDecoration: 'none'
    },
    menuIcon: {
      marginRight: theme.spacing(2),
      color: theme.palette.text.secondary
    },
    profileMenu: {
      padding: theme.spacing(2),
      minWidth: 300
    },
    profileIcon: {
      float: 'left',
      marginRight: theme.spacing(2),
      height: 50,
      width: 50,
      color: theme.palette.text.secondary
    },
    drawer: {
      width: drawerWidth
    },
    switchButton: {
      float: 'right'
    }
  }
))

const MyAppBar = (props) => {
  const classes = useStyle();
  const { can } = useCan();
  const [state, setState] = React.useState({ menuOpen: false, drawerOpen: false })
  const { backend, backendDispatch } = useContext(BackendContext)



  return (<div>
    <AppBar position="static" >
      <Toolbar>
        <Tooltip title="Ouvrir le menu"><IconButton onClick={(e) => setState({ ...state, drawerOpen: true })} color="inherit" ><MenuIcon /></IconButton></Tooltip>
        <Typography variant="h6" className={classes.title} >
          {backend.user && backend.user.groups.length ? backend.user.groups[0].instance.name : null}
        </Typography>
        <IconButton onClick={(e) => setState({ ...state, menuOpen: e.currentTarget })} color="inherit"><AccountCircleIcon /></IconButton>
        <Menu open={Boolean(state.menuOpen)} anchorEl={state.menuOpen} onClose={() => { setState({ ...state, menuOpen: null }) }}>
          <Box className={classes.profileMenu}>
            <AccountCircleIcon className={classes.profileIcon} />
            {localStorage.getItem('switchUser') ?
              <Tooltip title="Repasser au compte administrateur initial"><IconButton className={classes.switchButton} onClick={() => { localStorage.removeItem('switchUser'); document.location.href = '/' }}><SwapHorizontalCircleIcon /></IconButton></Tooltip> : null}
            <Typography variant="subtitle1" component="div">{backend.user.firstName} {backend.user.lastName}</Typography>
            <Typography variant="caption" component="div" color="textSecondary">{backend.user.email}</Typography>
          </Box>
          <Divider />
          <MenuList>
            <MenuItem><PersonIcon className={classes.menuIcon} /> Mon profil</MenuItem>
            <MenuItem onClick={() => { backendDispatch({ action: LOGOUT }) }}><ExitToAppIcon className={classes.menuIcon} /> Déconnexion</MenuItem>
          </MenuList>
        </Menu>
      </Toolbar>
      <Drawer anchor='left' open={state.drawerOpen} onClose={() => setState({ ...state, drawerOpen: false })} className={classes.drawer}>
        <Box className={classes.drawer}>
          <Box style={{ textAlign: 'right' }}>
            <Tooltip title="Fermer le menu"><IconButton onClick={() => setState({ ...state, drawerOpen: false })}><ArrowBackIcon /></IconButton></Tooltip>
          </Box>
          <List className={classes.drawer}>
            {AppRoutes.map((r, k) => {
              if (can(r.cap, r) && r.menuTitle) {
                return (<ListItem key={k} button component={Link} to={r.path} onClick={() => setState(state => ({ ...state, drawerOpen: false }))}>
                  <ListItemIcon>{r.menuIcon}</ListItemIcon>
                  <ListItemText>{r.menuTitle}</ListItemText>
                </ListItem>)
              }
              return null
            })}
          </List>
        </Box>
      </Drawer>
    </AppBar>
  </div >);
}

export default MyAppBar



