/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import { Checkbox, CircularProgress, createStyles, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import clone from 'clone';
import React, { useEffect } from 'react';
import { usePaginatedFetch } from '../Tool/Api';
import MyCheckbox from './MyCheckbox';

const useStyle = makeStyles(theme => createStyles({
  paper: {
    margin: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    padding: theme.spacing(2),
  },
  fieldsContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  fields: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}))

const AvailableAutorizations = [
  { id: "SHOW", label: "Voir le contenu de ce champ" },
  { id: "EDIT", label: "Modifier ce champ" },
];

const GroupAuthorizations = (props) => {
  const handleChange = (event) => {
    const value = clone(props.current.authorizations) || {}
    value[props.group.id] = value[props.group.id] || [];
    console.log('value', value);
    const index = value[props.group.id].indexOf(event.target.name);
    console.log('index', index);
    console.log('event.target.checked', event.target.checked);
    if ((index < 0 || typeof index === 'undefined') && event.target.checked) {
      value[props.group.id].push(event.target.name)
      value[props.group.id].sort()
      props.onChange(value);
    }
    else if (index > -1 && !event.target.checked) {
      value[props.group.id].splice(index, 1);
      value[props.group.id].sort()
      props.onChange(value);
    }
  }


  return (<>
    <Typography variant='h6'>{props.group.name}</Typography>
    {AvailableAutorizations.map((aa, index) =>
      <FormControlLabel
        key={index}
        control={<Checkbox label={aa.label} name={aa.id} checked={props.current.authorizations?.[props.group.id]?.indexOf(aa.id) > -1} onChange={handleChange} />}
        label={aa.label}
      />)}
  </>)
}

const AuthorizationForm = (props) => {
  const classes = useStyle();
  const { items, count, previous, next, loading, load, loadPrevious, loadNext } = usePaginatedFetch("/api/groups")

  useEffect(() => {
    load();
  }, []);

  return (<Paper elevation={2} className={classes.paper}>
    <Typography variant='h5'>Gestion des autorisation</Typography>
    <Typography variant='caption' color="textSecondary">Définissez ci-dessous les autorisation de lecture et de modification de ce champ pour chaque groupe d'utilisateur</Typography>
    {loading ?
      <CircularProgress /> :
      <Grid container>
        {items.map((item, i) => <Grid item xs={12} sm={4} key={i}><GroupAuthorizations group={item} {...props} /></Grid>)}
      </Grid>
    }
  </Paper>)
}

export default AuthorizationForm;