/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import React, { useState } from 'react';

import { Button, CircularProgress, FormControl, FormLabel, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Plugins } from '@capacitor/core';
import MapIcon from '@material-ui/icons/Map';

const { Geolocation } = Plugins;

const MyGPSField = (props) => {
  const designValue = (points) => <>{points.join(', ')} <Tooltip title="Afficher sur la carte"><IconButton href={`https://www.google.com/maps/search/?api=1&query=${points.join(',')}`} target="_blank" ><MapIcon /></IconButton></Tooltip></>
  const [display, setDisplay] = useState(Array.isArray(props.value) ? designValue(props.value) : 'Non définit');
  const [available, setAvailable] = useState(true)
  const updateGPS = () => {
    setAvailable(false)
    Geolocation.getCurrentPosition().then(coordinates => {
      const nv = [coordinates.coords.latitude, coordinates.coords.longitude]
      props.onChange(nv);
      setAvailable(true);
      setDisplay(designValue(nv));
    }).catch(() => {
      setDisplay("La fonction GPS n'est pas disponible sur votre appareil.")
    });
  }

  return (<FormControl component="fieldset">
    <FormLabel component="legent">{props.label}</FormLabel>
    <div>
      <Typography component='span'>Valeur actuelle : </Typography>
      <Typography component='span' color='textSecondary'>{display}</Typography>
      <Button onClick={updateGPS} variant='outlined' color='primary' disabled={!available}>{props.buttonLabel || "Détecter et inclure mes coordonnées GPS"}</Button>
    </div>
  </FormControl>)
}

export default MyGPSField;