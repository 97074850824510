/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import React, { useEffect } from 'react';
import { TextField, Box, Checkbox, Tooltip, Typography, FormControl, FormLabel, FormGroup, FormControlLabel, CircularProgress } from '@material-ui/core';
import ObjectSelection from '../Component/ObjectSelection';
import { useCan } from '../Context/BackendContext';
import { usePaginatedFetch } from '../Tool/Api';
import clone from 'clone';
import Label from '../Component/Label';
import { CapCats } from '../Component/CapCats';

const GroupForm = (props) => {
  const { can } = useCan();
  const { load, loading, items } = usePaginatedFetch("/api/capabilities");
  const { load: loadCategories, loading: loadingCategories, items: categories } = usePaginatedFetch("/api/categories");

  useEffect(() => { load(); loadCategories() }, []);

  const handleCapChange = (event) => {
    const capabilities = clone(props.entity.capabilities)
    const index = capabilities.indexOf(event.target.name);
    if (index < 0 && event.target.checked) {
      capabilities.push(event.target.name)
      props.setEntity({ ...props.entity, capabilities });
    }
    else if (index > -1 && !event.target.checked) {
      capabilities.splice(index, 1);
      props.setEntity({ ...props.entity, capabilities });
    }
  }

  if (loading || loadingCategories) return <CircularProgress />

  return (<>
    <TextField fullWidth label="Nom du groupe" value={props.entity.name} onChange={v => { props.setEntity({ ...props.entity, name: v.currentTarget.value }) }} variant="outlined" />
    {can("SUPER_ADMIN") ?
      <Box mt={2}>
        <ObjectSelection
          apiEndpoint="/api/instances"
          label="Instance"
          defaultValue={props.entity.instance}
          onChange={(e, instance) => { props.setEntity({ ...props.entity, instance }) }}
        />
      </Box> : null}
    <Box mt={2}>
      {loading ?
        <Typography>"Chargement des autorisations disponibles ..."</Typography> :
        <FormControl component="fieldset">
          <FormLabel component="legent">Autorisations accordées aux utilisateurs de ce groupe</FormLabel>
          <FormGroup>
            {items.map(i => <FormControlLabel
              key={i['@id']}
              control={<Checkbox label={i['@id']} name={i['@id']} checked={props.entity.capabilities.indexOf(i['@id']) > -1} onChange={handleCapChange} />}
              label={<><Label text={i['@id']} color="#d7d7d7" /> <Typography display='inline'>{i.name}</Typography> <CapCats cap={i['@id']} enabled={props.entity.capabilities.indexOf(i['@id']) > -1} entity={props.entity} setEntity={props.setEntity} items={categories} /></>}
            />)}

          </FormGroup>
        </FormControl>
      }
    </Box>
  </>)
}

export default GroupForm;