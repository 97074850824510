/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */


import React, { createRef, Suspense } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useFetch } from '../Tool/Api';
import { useCurrentUser } from '../Context/BackendContext';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {new Date().getFullYear()} -{' '}
      <Link color="inherit" href="https://agenceho5.com">
        Agence ho5
      </Link>
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const refs = {
  username: createRef(),
  password: createRef()
};



const LoginPage = (props) => {
  //const {enqueueSnackbar} = ;
  const classes = useStyles();
  const { load: loadUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const { loading: disabled, load } = useFetch('/authentication_token', 'POST', datas => {
    localStorage.setItem('API_TOKEN', datas.token);
    loadUser();
  }, error => { enqueueSnackbar(error.message, { variant: 'error' }) })

  const onSubmit = (e) => {
    e.preventDefault();
    localStorage.removeItem('API_TOKEN');
    load({
      email: refs.username.current.value,
      password: refs.password.current.value
    })
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Suspense display={disabled}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Connexion
            </Typography>
            {disabled ?
              <CircularProgress className={classes.form} />
              :
              <form className={classes.form} onSubmit={onSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Adresse email"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  inputRef={refs.username}
                  disabled={disabled}
                  type="email"
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Mot de passe"
                  name="password"
                  inputRef={refs.password}
                  disabled={disabled}
                  type="password"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={disabled && !refs.username.current.value.length}
                >
                  Se connecter
            </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Besoin d'aide pour vous connecter ?
                </Link>
                  </Grid>
                </Grid>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>
            }
          </Suspense>
        </div>
      </Grid>
    </Grid>
  );
}

export default LoginPage;
