/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */

import React, { createRef } from 'react';
import { Accordion, AccordionSummary, Typography, AccordionDetails, Box, TextField, Button, Tooltip, makeStyles, createStyles, FormControlLabel, Checkbox, AccordionActions, Switch } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import clone from 'clone';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import arrayMove from 'array-move';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FieldRender from './FieldRender';
import { emptyField, formComponents } from './Fields';
import ConditionForm from './ConditionForm';
import AuthorizationForm from './AuthorizationForm';

const useStyle = makeStyles(theme => createStyles(
  {
    dragIcon: {
      cursor: "ns-resize"
    },
    onDrag: {
      zIndex: 9999
    },
    deleteButton: {
      color: theme.palette.error.main
    }
  }
));

const DragHandle = sortableHandle(() => {
  const classes = useStyle();
  return (<span><DragHandleIcon className={classes.dragIcon} /></span>)
});

const SortableItem = SortableElement(React.memo(({ arrayIndex: index, value: f, entity, setEntity }) => {
  const classes = useStyle();
  const updateFieldProperty = (field, value) => {
    const fields = clone(entity.form.fields);
    fields[index][field] = value;
    setEntity({ ...entity, form: { ...entity.form, fields } })
  }
  const deleteField = () => {
    const fields = clone(entity.form.fields);
    fields.splice(index, 1);
    setEntity({ ...entity, form: { ...entity.form, fields } })
  }
  const component = formComponents.filter(i => i.name === f.component)[0] || null

  return (<Accordion elevation={4} defaultExpanded={!Boolean(f.label)} >
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Box mr={1} color="text.secondary"><DragHandle /></Box>
      <Typography variant="h6">{f.label || "Sans nom"}</Typography>&nbsp;
      {f.required ? <Tooltip title="Ce champ de formulaire est requis"><Typography color="error">*</Typography></Tooltip> : ''}
    </AccordionSummary>
    <AccordionDetails style={{ display: 'block' }}>
      <Box mt={2}><TextField label="Label du champ" autoFocus={!Boolean(f.label)} value={f.label} onChange={(e) => { updateFieldProperty('label', e.currentTarget.value) }} variant="outlined" required fullWidth></TextField></Box>
      <Box mt={2}>
        <Autocomplete
          label="Type de champ"
          options={formComponents}
          getOptionLabel={o => o.editorLabel}
          getOptionSelected={(o, v) => { return o.name === v.name }}
          value={component || null}
          onChange={(e, v) => { updateFieldProperty('component', v ? v.name : 'textField') }}
          variant="outlined"
          required
          fullWidth
          renderInput={(params) => <TextField {...params} label="Type de champ" variant="outlined" />}
        />
      </Box>
      <Box mt={2}><TextField label="Aide" helperText="Affiché sous le champ pour donner des indications complémentaire (comme le fait cette ligne de texte)" value={f.helperText} onChange={(e) => { updateFieldProperty('helperText', e.currentTarget.value) }} variant="outlined" fullWidth></TextField></Box>
      <Box mt={2}><FormControlLabel
        control={
          <Checkbox
            checked={f.required}
            onChange={(e) => { updateFieldProperty('required', e.target.checked) }}
          />
        }
        label="Ce champ est requis"
      /></Box>
      <Box mt={2}>
        <FormControlLabel
          control={<Switch checked={f.conditionnal} onChange={(e) => { updateFieldProperty('conditionnal', e.target.checked) }} />}
          label="Affichage conditionnel"
          helperText="Vous permet de n'afficher ce champ qu'à condition que certains autres champs soient remplis d'une certaine façon"
        />
      </Box>
      {!f.conditionnal || <Box mt={2}><ConditionForm fields={entity.form.fields} current={f} onChange={(c) => { updateFieldProperty('conditions', c) }} /></Box>}
      <Box mt={2}>
        <FormControlLabel
          control={<Switch checked={f.enableAuthorizations} onChange={(e) => { updateFieldProperty('enableAuthorizations', e.target.checked) }} />}
          label="Configurer des autorisations spécifiques pour ce champ"
          helperText="Vous permet de définir quelles personnes ont le droit ou non de voir ou de modifier ce champ."
        />
      </Box>
      {!f.enableAuthorizations || <Box mt={2}><AuthorizationForm fields={entity.form.fields} current={f} onChange={(c) => { updateFieldProperty('authorizations', c) }} /></Box>}
      {component.editorFields.map(ef => <Box mt={2}><FieldRender {...ef} value={f[ef.name]} onChange={(newValue) => { updateFieldProperty(ef.name, newValue) }} /></Box>)}
    </AccordionDetails>
    <AccordionActions>
      <Button className={classes.deleteButton} onClick={deleteField}>Supprimer ce champ</Button>
    </AccordionActions>
  </Accordion>)
}))

const SortableList = SortableContainer(({ entity, setEntity }) => {
  return (<div>
    {entity.form.fields.map((f, index) => <SortableItem key={f.name} index={index} arrayIndex={index} value={f} entity={entity} setEntity={setEntity} />)}
  </div>)
})

const FormBuilder = React.memo((props) => {
  const classes = useStyle();
  const dragContainer = createRef();

  const addEmptyField = () => {
    const newField = clone(emptyField);
    newField.name = Math.random().toString(36).substr(2, 9);
    props.setEntity({ ...props.entity, form: { ...props.entity.form, fields: [...props.entity.form.fields, newField] } })
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const entity = clone(props.entity);
    entity.form.fields = arrayMove(entity.form.fields, oldIndex, newIndex)
    props.setEntity(entity);
  };

  return (<>
    <div ref={dragContainer}><SortableList {...props} onSortEnd={onSortEnd} useDragHandle lockToContainerEdges helperClass={classes.onDrag} axis="y" lockAxis="y" helperContainer={dragContainer.current} /></div>
    <Box textAlign="center" my={2}>
      <Button variant="contained" color="primary" onClick={addEmptyField}><AddIcon /> Ajouter un champ</Button>
    </Box>
  </>)
})

export default FormBuilder;