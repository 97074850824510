import React, { useCallback, useReducer } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core';
import BackendContext, { initialState, BackendReducer } from './Context/BackendContext';
import clone from 'clone';
import themes from './Theme/index'
import theme from './Theme/default';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

const Index = (props) => {
  const savedTheme = localStorage.getItem('theme');
  const currentTheme = useCallback(() => {
    let selectectedTheme = null
    if (savedTheme) {
      selectectedTheme = clone(themes)[themes.findIndex(el => el.name === savedTheme)];
    }
    const chosenTheme = selectectedTheme?.theme || theme;

    document.head.querySelector('meta[name="theme-color"]').setAttribute('content', chosenTheme.palette.primary.main);
    window.postMessage({ type: 'theme-color', payload: chosenTheme.palette.primary.main }, '*');
    return chosenTheme
  }, [savedTheme]);
  const [backend, backendDispatch] = useReducer(BackendReducer, clone(initialState));
  return (<React.StrictMode>
    <ThemeProvider theme={currentTheme()}>
      <BackendContext.Provider value={{ backend, backendDispatch }} >
        <SnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
          <App />
        </SnackbarProvider>
      </BackendContext.Provider>
    </ThemeProvider>
  </React.StrictMode >)
}

ReactDOM.render(
  <Index />,
  document.getElementById('root')
);

// Call the element loader after the app has been rendered the first time
defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
