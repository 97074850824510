/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyle = makeStyles(theme => createStyles(
  {
    label: props => ({
      display: "inline-block",
      margin: theme.spacing(props.margin || .5),
      padding: theme.spacing(.5, 1),
      borderRadius: theme.spacing(.5),
      verticalAlign: 'middle',
      background: props.color || theme.palette[props.themeColor].main,
      color: theme.palette.getContrastText(props.color || theme.palette[props.themeColor].main),
      fontFamily: theme.typography.caption.fontFamily,
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.button.fontWeight,
      lineHeight: 1
    }),
  }
));

const Label = (props) => {
  const classes = useStyle(props);
  return (<div className={[classes.label]}>{props.text}</div>)
}

export default Label;