/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */

import React from 'react';
import { Button, createStyles, Grid, IconButton, makeStyles, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import clone from 'clone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { comparisons } from './Fields';
import FieldRender from './FieldRender';

const useStyle = makeStyles(theme => createStyles({
  paper: {
    margin: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    padding: theme.spacing(2),
  },
  fieldsContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  fields: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}))

const emptyCondition = {
  field: null,
  comparison: 'equal',
  value: null,
}

const Condition = React.memo((props) => {
  const classes = useStyle()
  const changeCondition = (option, newVal) => {
    const nc = clone(props.current.conditions);
    nc[props.andIndex][props.orIndex][option] = newVal;
    props.onChange(nc)
  }

  const deleteMe = () => {
    const nc = clone(props.current.conditions)
    nc[props.andIndex].splice(props.orIndex, 1);
    if (!nc[props.andIndex].length) {
      nc.splice(props.andIndex, 1)
    }
    props.onChange(nc)
  }

  // A - Sélection du champ à comparer 
  const formComponents = clone(props.fields).filter((el) => el.name !== props.current.name)
  const currentField = formComponents[formComponents.findIndex(el => el.name === props.current.conditions[props.andIndex][props.orIndex].field)] || null;
  console.log('formComponents', formComponents);
  const fieldField = <Autocomplete
    label="Champ à comparer"
    options={formComponents}
    getOptionLabel={o => o.label || ''}
    getOptionSelected={(o, v) => { return o.name === v.name }}
    value={currentField}
    onChange={(e, v) => { changeCondition('field', v ? v.name : null) }}
    variant="outlined"
    required
    fullWidth
    renderInput={(params) => <TextField {...params} label="Champ à comparer" variant="outlined" />}
  />

  // B - S&lection de la comparaison à appliquer
  const currentComparison = clone(comparisons)[comparisons.findIndex(el => el.name === props.current.conditions[props.andIndex][props.orIndex].comparison)]
  const fieldComparison = <Autocomplete
    label="Comparaison"
    options={comparisons}
    getOptionLabel={o => o.label}
    getOptionSelected={(o, v) => { return o.name === v.name }}
    value={currentComparison || null}
    onChange={(e, v) => { changeCondition('comparison', v ? v.name : 'equal') }}
    variant="outlined"
    required
    fullWidth
    renderInput={(params) => <TextField {...params} label="Type de champ" variant="outlined" />}
  />

  // C - Définition de la valeur à comparer
  //const fieldValue = <TextField value={props.current.conditions[props.andIndex][props.orIndex].value} onChange={e => changeCondition('value', e.currentTarget.value)} variant='outlined' disabled={!currentComparison.allowValue} />
  const fieldValue = currentField ? <FieldRender {...currentField} value={props.current.conditions[props.andIndex][props.orIndex].value} onChange={v => changeCondition('value', v)} /> : <i>Choisissez un champ</i>

  return (<div className={classes.fieldsContainer}><Grid container spacing={2} className={classes.fields}>
    <Grid item xs={12} lg={4}>{fieldField}</Grid>
    <Grid item xs={12} lg={4}>{fieldComparison}</Grid>
    <Grid item xs={12} lg={4}>{fieldValue}</Grid>
  </Grid><div><Tooltip title="Supprimer cette condition"><IconButton onClick={deleteMe}><DeleteForeverIcon /></IconButton></Tooltip></div></div>)
})

const OrCondition = React.memo((props) => {
  const classes = useStyle()
  const addCondition = () => {
    const nc = clone(props.current.conditions);
    nc[props.andIndex].push(clone(emptyCondition));
    props.onChange(nc);
  }

  return (<Paper elevation={2} className={classes.paper}>
    <Typography variant='h5'>Conditions OU</Typography>
    <Typography variant='caption' color="textSecondary">Au moins une de ces conditons doit être vrai pour que le champ soit affiché</Typography>
    { props.current.conditions[props.andIndex]?.map((c, i) => <Condition key={i} orIndex={i} {...props} />)}
    <div style={{ textAlign: 'right' }}><Button variant="contained" onClick={addCondition}>Ajouter une condition OU</Button></div>
  </Paper>)
})

const ConditionForm = React.memo((props) => {
  const classes = useStyle()
  const addCondition = () => {
    const nc = clone(props.current.conditions);
    nc.push([clone(emptyCondition)]);
    props.onChange(nc)
  }

  return (<Paper elevation={2} className={classes.paper}>
    <Typography variant='h5'>Conditions ET</Typography>
    <Typography variant='caption' color="textSecondary">Toutes ces conditons doivent être vrais pour que le champ soit affiché</Typography>
    { props.current.conditions.map((c, i) => <OrCondition key={i} andIndex={i} {...props} />)}
    <div style={{ textAlign: 'right' }}><Button variant="contained" onClick={addCondition}>Ajouter une condition ET</Button></div>
  </Paper>)
})

export default ConditionForm;