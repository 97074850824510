/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */

import React, { Fragment } from 'react';
import { TableCell, Tooltip } from "@material-ui/core";




export const GroupsTable = {
  'ref': {
    head: <TableCell align='left'>#</TableCell>,
    row: (row) => <TableCell align='left'>{row.id}</TableCell>
  },
  'name': {
    head: <TableCell align='left'>Nom</TableCell>,
    row: (row) => <TableCell align='left'>{row.name}</TableCell>
  },
  'date': {
    head: <TableCell align='left'>Mise à jour</TableCell>,
    row: (row) => <TableCell align='left'><Tooltip title={<Fragment>Création : {(new Date(row.created)).toLocaleString()}<br />Mise à jour : {(new Date(row.updated)).toLocaleString()}</Fragment>}><abbr>{(new Date(row.updated)).toLocaleDateString()}</abbr></Tooltip></TableCell>
  }
}