/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import React from 'react';
import clone from 'clone';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@material-ui/core';

const MyCheckboxes = (props) => {
  const componentProps = clone(props)
  const options = props.options
  delete componentProps.options
  delete componentProps.component

  const handleChange = (event) => {
    const value = clone(props.value) || []
    const index = value.indexOf(event.target.name);
    if (index < 0 && event.target.checked) {
      value.push(event.target.name)
      value.sort()
      props.onChange(value);
    }
    else if (index > -1 && !event.target.checked) {
      value.splice(index, 1);
      value.sort()
      props.onChange(value);
    }
  }

  return (<FormControl component="fieldset">
    <FormLabel component="legent">{props.label}</FormLabel>
    <FormGroup>
      {options.map(o => <FormControlLabel
        key={o}
        control={<Checkbox label={o} name={o} checked={props.value?.indexOf(o) > -1} onChange={handleChange} />}
        label={o}
      />)}
    </FormGroup>
  </FormControl>)

}

export default MyCheckboxes;