/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */

import { defaultTheme } from './default'
import { frFR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core';

const ho5Park = createTheme({
  ...defaultTheme,
  palette: {
    primary: { main: "#cc0033" },
    secondary: { main: "#747477" }
  }
}, frFR);

export default ho5Park;