/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */

import React, { Fragment, useEffect, useState, useImperativeHandle } from 'react';
import { Typography, Paper, Button, Grid, IconButton, TextField, Box, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, InputAdornment, ButtonGroup, useTheme, makeStyles, createStyles, useMediaQuery } from '@material-ui/core';
import MyTable from '../Component/MyTable';
import RefreshIcon from '@material-ui/icons/Refresh';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import { usePaginatedFetch, useFetch } from '../Tool/Api';
import PropTypes from 'prop-types';
import clone from 'clone';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { useSnackbar } from 'notistack';
import Filters from './Filters';
const useStyle = makeStyles(theme => createStyles(
  {
    navigationButtons: {
      display: "inlineBlock",
      margin: theme.spacing(1),
      verticalAlign: 'middle'
    }
  }
));

const ListPage = React.memo(React.forwardRef((props, ref) => {
  const classes = useStyle();
  const { enqueueSnackbar } = useSnackbar()
  const [dial, setDial] = useState(false);
  const onSaveSuccess = () => {
    getSearch();
    setEntity(clone(props.emptyEntity));
    setDial(false);
    setLock({});
    enqueueSnackbar("Les modifications apportées ont bien été enregistrées", { variant: 'success' })
  }
  const onSaveError = error => { setLock({}); enqueueSnackbar(error['hydra:description'], { variant: 'error' }) }
  const { items, count, previous, next, loading, load, loadPrevious, loadNext } = usePaginatedFetch(props.apiEndpoint)
  const { load: deleteItem, loadingDelete } = useFetch(null, 'DELETE', () => { getSearch(); enqueueSnackbar("L'entrée a bien été supprimée", { variant: 'success' }) }, error => { enqueueSnackbar(error['hydra:description'], { variant: 'error' }) });
  const { load: addItem, loadingAdd } = useFetch(props.apiEndpoint, 'POST', onSaveSuccess, onSaveError);
  const { load: updateItem, loadingPut } = useFetch(null, 'PUT', onSaveSuccess, onSaveError);
  const [search, setSearch] = useState('');
  const [entity, setEntity] = useState(clone(props.emptyEntity))
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [fullsize, setFullsize] = useState(false);
  const [lock, setLock] = useState({});
  const [filters, setFilters] = useState({});

  const getSearch = props.getSearch || (() => {
    const searchUrl = props.searchUrl ? props.searchUrl.replaceAll(/\{search\}/g, search) : `${props.apiEndpoint}?search=${search}`
    load(search ? searchUrl : props.apiEndpoint)
  })

  useEffect(() => { load(null, false) }, [load])

  const saveEntity = () => {
    if (entity['@id']) {
      updateItem(entity, entity['@id'])
    }
    else {
      addItem(entity);
    }
  }

  const deleteEntity = (entity) => {
    deleteItem(null, entity['@id'])
  }

  useImperativeHandle(ref, () => ({
    setDial,
    setEntity,
    setLock,
    deleteEntity,
    load,
    search
  }))

  const filtersChange = (filters) => {
    setFilters(filters);
    console.log(filters)
    const params = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      filters[key].forEach(value => {
        params.append(`${key}[]`, value['@id']);
      })
    })

    load(`${props.apiEndpoint}?${params.toString()}`, false);
  }

  return (<Fragment>
    <Paper>
      {loading || loadingAdd || loadingPut || loadingDelete ?
        <Box p={4} textAlign="center"><Typography variant="h3">Chargement ...</Typography></Box> :
        <Fragment>
          <Grid container justifyContent="space-between">
            <Grid item style={{ display: 'flex' }}><Box p={1}><form onSubmit={(e) => { e.preventDefault(); getSearch() }}>
              <TextField
                label="Rechercher ..."
                variant="outlined"
                size="small"
                value={search}
                onChange={e => setSearch(e.currentTarget.value)}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Rechercher"
                        onClick={getSearch}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                }} />
            </form></Box>
              {props.filters && <Filters filters={filters} onChange={filtersChange} filtersComponent={props.filters} />}
            </Grid>
            <Grid item>
              <Tooltip title="Raffraîchir la liste"><IconButton onClick={() => { setSearch(''); load() }}><RefreshIcon /></IconButton></Tooltip>
              {next || previous ? <ButtonGroup className={classes.navigationButtons}>
                <Tooltip title="Voir les résultats précédents"><Button size="small" onClick={loadPrevious} disabled={!previous}><ChevronLeftIcon /></Button></Tooltip>
                <Tooltip title="Voir les résultats suivants"><Button size="small" onClick={loadNext} disabled={!next}><ChevronRightIcon /></Button></Tooltip>
              </ButtonGroup> : null}
            </Grid>
          </Grid>
          {count > 0 ?
            <Fragment>
              <MyTable cols={props.entityTable} values={items} />
            </Fragment> :
            <Box p={4} textAlign="center"><Typography variant="h3">Aucun résultat à afficher</Typography><br />{props.addButton}</Box>}
        </Fragment>
      }
    </Paper>
    <Dialog open={dial} fullScreen={fullsize || isMobile} fullWidth maxWidth='md'>
      <form onSubmit={(e) => { e.preventDefault(); saveEntity(); setLock(lock => ({ ...lock, mainForm: true })) }}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item><Typography variant="h4">Ajouter une entrée</Typography></Grid>
            {isMobile ? null : <Grid item><Tooltip title="Activer / désactiver l'édition en plein écran"><IconButton onClick={() => setFullsize(!fullsize)}>{fullsize ? <FullscreenExitIcon /> : <FullscreenIcon />}</IconButton></Tooltip></Grid>}
          </Grid>
        </DialogTitle>
        <DialogContent>
          {React.createElement(props.editForm, { entity, setEntity, setLock })}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setEntity(clone(props.emptyEntity)); setDial(false) }}>Annuler</Button>
          <Button type="submit" variant="contained" color="primary" startIcon={<SaveIcon />} disabled={Boolean(Object.keys(lock).length)}>Enregistrer</Button>
        </DialogActions>
      </form>
    </Dialog>
  </Fragment>)
}))

ListPage.propTypes = {
  apiEndpoint: PropTypes.string.isRequired,
  emptyEntity: PropTypes.object.isRequired,
  entityTable: PropTypes.object.isRequired,
  addButton: PropTypes.element.isRequired,
  editForm: PropTypes.elementType.isRequired,
  getSearch: PropTypes.func,
  searchUrl: PropTypes.string
}

export default ListPage;