/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import HttpsIcon from '@material-ui/icons/Https';
import { useFetch } from '../Tool/Api';
import { useSnackbar } from 'notistack';

const WordpressConnector = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [login, setLogin] = useState(null);
  const [pass, setPass] = useState(null);

  const { load, loading } = useFetch(`/api/instance/${props.instance.id}/wplink`, 'POST', () => setSuccess(true), (error) => enqueueSnackbar(error['hydra:description'], { variant: 'error' }))

  if (!props.instance.wordpress) {
    return null;
  }

  return (<>
    <Tooltip title="Vérifier la connexion avec wordpress et synchroniser les utilisateurs"><IconButton onClick={() => setOpen(true)}><HttpsIcon /></IconButton></Tooltip>
    <Dialog open={open} onClose={() => { setOpen(false) }}>
      <DialogTitle>Connexion au site Wordpress</DialogTitle>
      <DialogContent>
        {loading ? <Box my={8} textAlign="center"><CircularProgress /></Box> :
          success ? <>
            <Box my={8} textAlign="center"><Typography variant="h3">Synchronisation terminée avec succès</Typography></Box>
            <Box my={4} textAlign="center"><Button variant="contained" color="primary" onClick={() => { setOpen(false); setSuccess(false); setLogin(null); setPass(null) }}>Fermer</Button></Box>
          </> :
            <>
              <Box mt={2}><TextField fullWidth label="Identifiant wordpress" helperText="L'utilisateur wordpress doit disposer d'un statut administrateur ou au moins d'un statut lui permetant de lister tous les utilisateurs" value={login} onChange={v => { setLogin(v.currentTarget.value) }} variant="outlined" /></Box>
              <Box mt={2}><TextField fullWidth type="password" label="Mot de passe wordpress" value={pass} onChange={v => { setPass(v.currentTarget.value) }} variant="outlined" /></Box>
              <Box my={4} textAlign="center"><Button variant="contained" color="primary" disabled={!login || !pass} onClick={() => load({ login, password: pass })}>Se connecter</Button></Box>
            </>}
      </DialogContent>
    </Dialog>
  </>)
}

export default WordpressConnector;