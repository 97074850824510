/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import React from 'react';
import { TextField, Box } from '@material-ui/core';
import ObjectSelection from '../Component/ObjectSelection';
import { useCan } from '../Context/BackendContext';

const UserForm = (props) => {
  const { can } = useCan();
  return (<>
    <Box mt={2}><TextField fullWidth label="Nom de famille" value={props.entity.lastName} onChange={v => { props.setEntity({ ...props.entity, lastName: v.currentTarget.value }) }} variant="outlined" /></Box>
    <Box mt={2}><TextField fullWidth label="Prénom" value={props.entity.firstName} onChange={v => { props.setEntity({ ...props.entity, firstName: v.currentTarget.value }) }} variant="outlined" /></Box>
    <Box mt={2}><TextField fullWidth type="email" label="Adresse email" required value={props.entity.email} onChange={v => { props.setEntity({ ...props.entity, email: v.currentTarget.value }) }} variant="outlined" /></Box>
    <Box mt={2}><TextField fullWidth label="Mot de passe" helperText={Boolean(props.entity['@id']) ? "Laissez ce champ vide pour conserver le mot de passe actuel" : ''} required={!Boolean(props.entity['@id'])} value={props.entity.password} onChange={v => { props.setEntity({ ...props.entity, password: v.currentTarget.value }) }} variant="outlined" /></Box>
    <Box mt={2}>
      <ObjectSelection
        multiple
        label="Entité(s)"
        apiEndpoint="/api/entities"
        defaultValue={props.entity.entities}
        onChange={(e, entities) => { props.setEntity({ ...props.entity, entities }) }}
      />
    </Box>
    <Box mt={2}>
      <ObjectSelection
        multiple
        apiEndpoint="/api/groups"
        label="Groupe(s)"
        defaultValue={props.entity.groups}
        onChange={(e, groups) => { props.setEntity({ ...props.entity, groups }) }}
      />
    </Box>
    {can("SUPER_ADMIN") ?
      <Box mt={2}>
        <ObjectSelection
          apiEndpoint="/api/instances"
          label="Instance"
          defaultValue={props.entity.instance}
          onChange={(e, instance) => { props.setEntity({ ...props.entity, instance }) }}
        />
      </Box> : null}
  </>)
}

export default UserForm;