/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import { Table, TableContainer, TableHead, TableRow, TableBody } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useCan } from '../Context/BackendContext';

const MyTable = (props) => {
  const show = props.show || Object.keys(props.cols);
  const { can } = useCan();
  return (<TableContainer component="div">
    <Table size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          {show.map((i, key) => { if (!props.cols[i].cap || can(props.cols[i].cap)) { return (React.cloneElement(props.cols[i].head, { key })) } })}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.values.map(row =>
          <TableRow key={row.id}>
            {show.map((i, key) => { if (!props.cols[i].cap || can(props.cols[i].cap)) { return (React.cloneElement(props.cols[i].row(row), { key })) } })}
          </TableRow>)
        }
      </TableBody>
    </Table>
  </TableContainer >)
}

MyTable.propTypes = {
  show: PropTypes.arrayOf(PropTypes.string),
  cols: PropTypes.object,
  values: PropTypes.arrayOf(PropTypes.object)
}

export default MyTable;