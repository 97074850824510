/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import React from 'react';
import { Box } from '@material-ui/core';
import { useCan } from '../Context/BackendContext';
import ObjectSelection from '../Component/ObjectSelection';
import FormRender from '../FormBuilder/FormRender';

const TicketForm = (props) => {
  console.log('props.entity', props.entity);
  const { can } = useCan();

  const updateFieldProperty = (field, value) => {
    props.setEntity(entity => ({ ...entity, datas: { ...entity.datas, [field]: value } }))
  }

  return (<>
    <Box mt={2}>
      <ObjectSelection
        apiEndpoint="/api/categories"
        label="Catégorie"
        filterOptions={(category) => can("TICKET_SELECT_CAT", { category })}
        defaultValue={props.entity.category}
        onChange={(e, category) => { props.setEntity({ ...props.entity, category }) }}
      />
    </Box>
    {can("TICKET_SET_STATUS") ?
      <Box mt={2}>
        <ObjectSelection
          apiEndpoint="/api/statuses"
          label="Status"
          defaultValue={props.entity.status}
          onChange={(e, status) => { props.setEntity({ ...props.entity, status }) }}
        />
      </Box> : null
    }
    {can("TICKET_SET_ENTITIES") &&
      <Box mt={2}>
        <ObjectSelection
          apiEndpoint="/api/entities"
          label="Entité(s)"
          defaultValue={props.entity.entities}
          multiple
          onChange={(e, entities) => { props.setEntity({ ...props.entity, entities }) }}
        />
      </Box>}
    {can("SUPER_ADMIN") ? <>
      <Box mt={2}>
        <ObjectSelection
          apiEndpoint="/api/users"
          label="Auteur"
          getOptionLabel={(option) => `${option.firstName || ''} ${option.lastName || ''} (${option.email})`}
          defaultValue={props.entity.author}
          onChange={(e, author) => { props.setEntity({ ...props.entity, author }) }}
        />
      </Box>
      <Box mt={2}>
        <ObjectSelection
          apiEndpoint="/api/instances"
          label="Instance"
          defaultValue={props.entity.instance}
          onChange={(e, instance) => { props.setEntity({ ...props.entity, instance }) }}
        />
      </Box>
    </>
      : null}
    <FormRender onChange={(fname, newValue) => { updateFieldProperty(fname, newValue) }} form={props.entity.category?.form} entity={props.entity} setLock={props.setLock} />
  </>)
}

export default TicketForm;