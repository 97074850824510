/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */

import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import StoreIcon from '@material-ui/icons/Store';
import CategoryIcon from '@material-ui/icons/Category';
import AdjustIcon from '@material-ui/icons/Adjust';
import DashBoardPage from '../Page/DashBoardPage';
import UserPage from '../Page/UserPage';
import GroupPage from '../Page/GroupPage';
import EntityPage from '../Page/EntityPage';
import InstancePage from '../Page/InstancePage';
import FolderIcon from '@material-ui/icons/Folder';
import CategoryPage from '../Page/CategoryPage';
import CommentIcon from '@material-ui/icons/Comment';
import TicketPage from '../Page/TicketPage';
import StatusPage from '../Page/StatusPage';
import AlertsPage from '../Page/AlertPage';
import NotificationsIcon from '@material-ui/icons/Notifications';

const AppRoutes = [
  {
    menuTitle: "Tableau de bord",
    menuIcon: <DashboardIcon />,
    path: "/",
    content: <DashBoardPage />,
    cap: 'DASHBOARD'
  },
  {
    menuTitle: "Tickets",
    menuIcon: <CommentIcon />,
    path: "/ticket",
    content: <TicketPage />,
    cap: 'TICKET_ADD'
  },
  {
    menuTitle: "Catégories",
    menuIcon: <FolderIcon />,
    path: "/category",
    content: <CategoryPage />,
    cap: 'MANAGE_CATEGORIES'
  },
  {
    menuTitle: "Entités",
    menuIcon: <StoreIcon />,
    path: "/entity",
    content: <EntityPage />,
    cap: 'MANAGE_ENTITIES'
  },
  {
    menuTitle: "Utilisateurs",
    menuIcon: <PersonIcon />,
    path: "/user",
    content: <UserPage />,
    cap: 'MANAGE_USERS'
  },
  {
    menuTitle: "Groupes",
    menuIcon: <GroupIcon />,
    path: "/group",
    content: <GroupPage />,
    cap: 'MANAGE_GROUPS'
  },
  {
    menuTitle: "Statuts",
    menuIcon: <AdjustIcon />,
    path: "/statuses",
    content: <StatusPage />,
    cap: 'MANAGE_STATUSES'
  },
  {
    menuTitle: "Alertes",
    menuIcon: <NotificationsIcon />,
    path: "/alerts",
    content: <AlertsPage />,
    cap: 'MANAGE_ALERTS'
  },
  {
    menuTitle: "Instances",
    menuIcon: <CategoryIcon />,
    path: "/instance",
    content: <InstancePage />,
    cap: 'SUPER_ADMIN'
  }
]
export default AppRoutes