/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import React, { useRef } from 'react';
import { Container, Typography, Grid, IconButton, Box, Tooltip, TableCell, Button, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { InstancesTable } from '../Tables/InstancesTable';
import EditIcon from '@material-ui/icons/Edit';
import clone from 'clone';
import ListPage from '../Component/ListPage';
import InstanceForm from '../Form/InstanceForm';
import Alert from '@material-ui/lab/Alert';
import CategoryIcon from '@material-ui/icons/Category';
import GridOnIcon from '@material-ui/icons/GridOn';
import { useFetch } from '../Tool/Api';
import CachedIcon from '@material-ui/icons/Cached';
import WordpressConnector from '../Component/WordpressConnector';

const emptyInstance = {
  name: null,
  numbering: 'simple',
  increment: 1,
}

const useStyles = makeStyles(theme => ({
  btnError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
}))

const InstancePage = React.memo((props) => {
  // Constantes
  const ListPageRef = useRef()
  const classes = useStyles();
  const { load: loadUpdate, loading: loadingUpdate } = useFetch('', 'GET', (response) => alert(response.message), (error) => alert(error['hydra:description']));

  // Colones du tableau
  // -- Actions
  InstancesTable.actions = {
    head: <TableCell align="right">Actions</TableCell>,
    row: (row) => <TableCell align="right">
      {row.googlesheet ?
        <>
          <Tooltip title="Forcer la mise à jour complète du fichier Google Sheet"><IconButton onClick={() => loadUpdate(null, '/api/instance/' + row.id + '/goo_update')} disabled={loadingUpdate}><CachedIcon /></IconButton></Tooltip>
          <Tooltip title="Ouvrir le fichier Google Sheet dans un nouvel onglet"><IconButton href={row.googlesheet} target="_blank"><GridOnIcon /></IconButton></Tooltip>
        </>
        : null
      }
      <WordpressConnector instance={row} />
      <Tooltip title="Modifier l'instance"><IconButton onClick={() => { ListPageRef.current.setEntity(clone(row)); ListPageRef.current.setDial(true) }}><EditIcon /></IconButton></Tooltip>
    </TableCell >
  }

  //Création du bouton d'ajout
  const addButton = <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => ListPageRef.current.setDial(true)}>Ajouter une instance</Button>

  return (<Container>
    <Grid container justifyContent="space-between" spacing={2}>
      <Grid item><Typography variant="h1"><CategoryIcon /> Instances</Typography></Grid>
      <Grid item>{addButton}</Grid>
    </Grid>

    <Box mt={4}>
      <Alert variant="filled" severity="info">
        Pour des raisons de sécurité et afin d'éviter toute suppression involontaire irréversible, la suppression d'instance n'est pas implémentée depuis cette interface. Une instance ne peut être supprimée que depuis l'invité de commande.
      </Alert>
    </Box>

    <Box mt={4}>
      <ListPage
        apiEndpoint="/api/instances"
        emptyEntity={emptyInstance}
        entityTable={InstancesTable}
        addButton={addButton}
        editForm={InstanceForm}
        ref={ListPageRef}
      />
    </Box>

  </Container>)
})

export default InstancePage;