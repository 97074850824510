/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */

import theme from "./default";
import atvTheme from './AtoutVert';
import ho5Park from './ho5park';

const themes = [
  { name: 'default', label: "Theme par défaut", theme },
  { name: 'atv', label: "Atout Vert", theme: atvTheme },
  { name: 'ho5park', label: "ho5park", theme: ho5Park }
]

export default themes