/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import React from 'react';
import { TextField, Box } from '@material-ui/core';
import { useCan } from '../Context/BackendContext';
import ObjectSelection from '../Component/ObjectSelection';

const EntityForm = (props) => {
  const { can } = useCan();
  return (<>
    <TextField fullWidth label="Nom de l'entité" value={props.entity.name} onChange={v => { props.setEntity({ ...props.entity, name: v.currentTarget.value }) }} variant="outlined" />
    {can("SUPER_ADMIN") ?
      <Box mt={2}>
        <ObjectSelection
          apiEndpoint="/api/instances"
          label="Instance"
          defaultValue={props.entity.instance}
          onChange={(e, instance) => { props.setEntity({ ...props.entity, instance }) }}
        />
      </Box> : null}
  </>)
}

export default EntityForm;