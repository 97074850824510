import { Checkbox, CircularProgress, createStyles, IconButton, makeStyles, Menu, MenuItem, Tooltip } from '@material-ui/core';
import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DehazeIcon from '@material-ui/icons/Dehaze';
import { usePaginatedFetch } from '../Tool/Api';
import { useEffect } from 'react';
import clone from 'clone';

const useStyle = makeStyles(theme => createStyles(
  {
    allButton: {
      color: theme.palette.success.main,
    }
  }
));

export const CapCats = ({ cap, enabled, entity, setEntity, items }) => {

  const classes = useStyle();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const isAll = !entity.capcats || !entity.capcats[cap] || entity.capcats[cap].length === 0 || items.every(i => entity.capcats[cap].includes(i.id));

  if (!enabled || !/^TICKET_/.test(cap) || ['TICKET_SET_STATUS', 'TICKET_SHOW_STATUS', 'TICKET_SET_ENTITIES'].includes(cap)) {
    // DISABLE for non ticket caps
    // Disable for TICKET_SET_STATUS, TICKET_SHOW_STATUS, TICKET_SET_ENTITIES due to complexity of implementation for these caps
    return null;
  }

  const handleToogle = (itemId) => {
    const capcats = { ...clone(entity.capcats) };

    if (!capcats[cap]) {
      // Si cette capacité n'a pas encore été initialisée, alors elle était automatiquement valide pour toutes les catégories
      // On est donc arrivé ici forcément en désactivant une catégorie pour cette capacité
      // On iinitialise donc la liste des catégories pour cette capacité en y incluant toutes les catégories sauf celle sélectionnée (itemId)
      capcats[cap] = items.filter(i => i.id !== itemId).map(i => i.id);
      setEntity({ ...entity, capcats });
      return
    }

    // Si l'on arrive jusqu'ici, c'est qu'une configuration existait déjà pour cette capacité
    if (capcats[cap].indexOf(itemId) > -1) {
      // Si la catégorie est déjà dans la liste, alors on la retire
      capcats[cap].splice(capcats[cap].indexOf(itemId), 1);
    } else {
      // Sinon on l'ajoute
      capcats[cap].push(itemId);
    }

    // cas particulier : Il est interdit de n'avoir aucune catégorie pour une capacité.
    // Si on vient d'enlever la dernière catégorie (et donc que la liste est vide),
    // alors, on réactive toutes les catégories pour cette capacité
    if (capcats[cap].length === 0) {
      capcats[cap] = items.map(i => i.id);
    }
    console.log("capcats", capcats)
    setEntity({ ...entity, capcats });
  }

  console.log("items", items)
  return <>
    <Tooltip title={isAll ? "Autorisation valable pour toutes les catégories" : "Autorisations personnalisées"} ><IconButton size='small' color="success" onClick={() => setOpen(!open)} ref={anchorRef} >
      {isAll ? <CheckIcon className={classes.allButton} /> : <DehazeIcon />}
      <ArrowDropDownIcon />
    </IconButton></Tooltip>
    <Menu open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current} >
      {items.map(cat => <MenuItem key={cat.id} onClick={() => { handleToogle(cat.id) }}>
        <Checkbox checked={entity.capcats?.[cap]?.includes(cat.id) || isAll} onChange={() => { handleToogle(cat.id) }} />
        <span>{cat.name}</span>
      </MenuItem>)}
    </Menu>
  </>
}