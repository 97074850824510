/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import React, { useEffect } from 'react';
import { TextField, Box, Checkbox, Tooltip, Typography, FormControl, FormLabel, FormGroup, FormControlLabel, Switch } from '@material-ui/core';
import ObjectSelection from '../Component/ObjectSelection';
import { useCan } from '../Context/BackendContext';
import { usePaginatedFetch } from '../Tool/Api';
import clone from 'clone';
import { BlockPicker } from 'react-color';
import MyColorField from '../FormBuilder/MyColorField';
import MyCheckbox from '../FormBuilder/MyCheckbox';

const AlertForm = (props) => {
  const { can } = useCan();
  const { load, loading, items } = usePaginatedFetch("/api/notifications");

  useEffect(() => { load() }, []);

  const handleNotificationsChange = (event) => {
    const notifications = clone(props.entity.notifications)
    const index = notifications.indexOf(event.target.name);
    if (index < 0 && event.target.checked) {
      notifications.push(event.target.name)
      props.setEntity({ ...props.entity, notifications });
    }
    else if (index > -1 && !event.target.checked) {
      notifications.splice(index, 1);
      props.setEntity({ ...props.entity, notifications });
    }
  }

  return (<>
    <Box mt={2}>
      <TextField fullWidth label="Email du destinataire" value={props.entity.email} onChange={v => { props.setEntity({ ...props.entity, email: v.currentTarget.value }) }} variant="outlined" />
    </Box>
    <Box mt={2}>
      <FormControlLabel
        control={<Switch checked={props.entity.allCategories} onChange={(e) => { props.setEntity({ ...props.entity, allCategories: e.target.checked }) }} />}
        label="Toutes les catégories"
        helperText="Envoyer cette alertes pour toutes les catégories"
      />
    </Box>
    {props.entity.allCategories ? null :
      <Box mt={2}>
        <ObjectSelection
          multiple
          apiEndpoint="/api/categories"
          label="Limiter à ces categories"
          defaultValue={props.entity.categories}
          onChange={(e, categories) => { props.setEntity({ ...props.entity, categories }) }}
        />
      </Box>
    }
    <Box mt={2}>
      <FormControlLabel
        control={<Switch checked={props.entity.allEntities} onChange={(e) => { props.setEntity({ ...props.entity, allEntities: e.target.checked }) }} />}
        label="Toutes les entités"
        helperText="Envoyer cette alerte pour lors de la création d'un ticket par un membre de n'importe quelle entité"
      />
    </Box>
    {props.entity.allEntities ? null :
      <Box mt={2}>
        <ObjectSelection
          multiple
          apiEndpoint="/api/entities"
          label="Limiter à ces entités"
          defaultValue={props.entity.entities}
          onChange={(e, entities) => { props.setEntity({ ...props.entity, entities }) }}
        />
      </Box>
    }
    {can("SUPER_ADMIN") ?
      <Box mt={2}>
        <ObjectSelection
          apiEndpoint="/api/instances"
          label="Instance"
          defaultValue={props.entity.instance}
          onChange={(e, instance) => { props.setEntity({ ...props.entity, instance }) }}
        />
      </Box> : null}
    <Box mt={2}>
      {loading ?
        <Typography>"Chargement des notifications disponibles ..."</Typography> :
        <FormControl component="fieldset">
          <FormLabel component="legent">Evènement pour le(s)quel(s) déclencher une alerte</FormLabel>
          <FormGroup>
            {items.map(i => <FormControlLabel
              key={i['@id']}
              control={<Checkbox label={i['@id']} name={i['@id']} checked={props.entity.notifications.indexOf(i['@id']) > -1} onChange={handleNotificationsChange} />}
              label={<Tooltip title={i['@id']}><attr>{i.name}</attr></Tooltip>}
            />)}

          </FormGroup>
        </FormControl>
      }
    </Box>
  </>)
}

export default AlertForm;