/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */

import React from 'react';
import clone from 'clone';
import { formComponents } from './Fields';


const FieldRender = (props) => {
  const componentOptions = formComponents.filter(c => c.name === props.component)[0];
  const Component = componentOptions.component
  const componentProps = clone(props);

  /*var readable = true;
  var writable = true;
  console.log('props', props);
  if (props.enableAuthorizations) {
    currentUser.groups.map(g => {
      if (props.authorizations[g.id].indexOf("SHOW") === -1) {
        readable = false;
        writable = false;
      }
      if (props.authorizations[g.id].indexOf("EDIT") === -1) {
        writable = false;
      }
    })
  }

  if (!readable) {
    return null;
  }*/

  delete componentProps.component
  return (<>
    <Component
      {...componentOptions.defaultProps}
      {...componentProps}
      value={props.onLoadValue?.(props.value) || componentOptions.onLoadValue?.(props.value) || props.value}
      onChange={(...args) => {
        let val = undefined;
        if (props.onChangeValue) {
          val = props.onChangeValue(...args)
        }
        else if (componentOptions.onChangeValue) {
          val = componentOptions.onChangeValue(...args)
        }
        else {
          val = args[0].target.value
        }
        props.onChange(val)
      }
      }
      setLock={newVal => props.setLock?.(lock => {
        if (newVal) {
          return { ...lock, [props.name]: true }
        }
        else {
          const nl = clone(lock);
          delete nl[props.name];
          return nl;
        }
      })}
    />
  </>)
}

export default FieldRender;