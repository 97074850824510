/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import React, { useEffect, useState } from 'react';
import { useFetch } from '../Tool/Api';
import DescriptionIcon from '@material-ui/icons/Description';
import { Box, Button, CircularProgress, createStyles, Dialog, Grid, makeStyles, Typography } from '@material-ui/core';


const useStyle = makeStyles(theme => createStyles({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fullScreen: {
    width: '100%',
    height: '100%',
    maxHeight: '100vh',
    border: 'none',
    padding: 0,
  },
  twoColsButton: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  previewZone: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.text.primary,
    maxHeight: '100%'
  },
  previewImage: {
    maxWidth: '100%',
    maxHeight: '100%',
  }
}))

const Media = React.memo((props) => {
  const [image, setImage] = useState(null);
  const { load, loading, errors } = useFetch(props.mediaId, 'GET', data => setImage(data))
  const [preview, setPreview] = useState(false);
  const classes = useStyle();
  useEffect(() => {
    if (!image && !loading) {
      load(null, null, false)
    }
  }, []);

  if (errors) {
    return <div className={classes.loadingContainer}>Erreur : {errors["hydra:description"]}</div>
  }

  if (!image || loading) {
    return <div className={classes.loadingContainer}><CircularProgress /></div>
  }

  else {
    console.log(errors);
    const previewDialog = <Dialog fullScreen open={preview} onClose={() => setPreview(false)}>
      <Grid container className={classes.fullScreen}>
        <Grid item xs={12} sm={9} lg={10} className={classes.previewZone}>
          {(['jpg', 'jpeg', 'png']).includes(image?.contentUrl?.split('.').pop().toLowerCase()) ?
            <img src={process.env.REACT_APP_API_ROOT + image.contentUrl} alt={image.contentUrl} className={classes.previewImage} /> :
            <iframe title="Aperçu" src={process.env.REACT_APP_API_ROOT + image.contentUrl} className={classes.fullScreen} />
          }
        </Grid>
        <Grid item xs={12} sm={3} lg={2} >
          <Box px={2} py={1} ><Typography variant='h3'>Aperçu de la pièce jointe</Typography></Box>
          <Box px={2} py={1} textAlign='right' ><Button onClick={() => setPreview(false)} variant="outlined" color="primary" >Fermer</Button></Box>
          {props.actions}
        </Grid>
      </Grid>
    </Dialog>
    if ((['jpg', 'jpeg', 'png']).includes(image?.contentUrl?.split('.').pop().toLowerCase())) {
      return (<><a href={process.env.REACT_APP_API_ROOT + image.contentUrl} onClick={(e) => { e.preventDefault(); setPreview(true) }}><img src={process.env.REACT_APP_API_ROOT + image.contentUrl} alt={image.contentUrl} className={props.className} /></a>{previewDialog}</>)
    }
    else {
      return <><a href={process.env.REACT_APP_API_ROOT + image.contentUrl} onClick={(e) => { e.preventDefault(); setPreview(true) }}><DescriptionIcon className={props.className} /></a>{previewDialog}</>
    }
  }

})

export default Media;