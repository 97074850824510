/***
 *                                       _           _____
 *                                      | |         |  ___|
 *      __ _  __ _  ___ _ __   ___ ___  | |__   ___ |___ \
 *     / _` |/ _` |/ _ \ '_ \ / __/ _ \ | '_ \ / _ \    \ \
 *    | (_| | (_| |  __/ | | | (_|  __/ | | | | (_) /\__/ /
 *     \__,_|\__, |\___|_| |_|\___\___| |_| |_|\___/\____/
 *            __/ |
 *           |___/
 *
 *           >> https://agenceho5.com
 */
import React, { useCallback, useEffect, useState } from 'react';
import clone from 'clone';
import { Button, IconButton, Box, InputLabel, useTheme, makeStyles, createStyles, LinearProgress, useMediaQuery } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { usePostFile } from '../Tool/Api';
import DescriptionIcon from '@material-ui/icons/Description';
import Media from '../Component/Media';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useSnackbar } from 'notistack';
import { Plugins, CameraResultType } from '@capacitor/core';
import { decode } from "base64-arraybuffer";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

const { Camera } = Plugins;

const useStyle = makeStyles(theme => createStyles({
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderWidth: theme.spacing(.25),
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.primary.light,
    borderStyle: 'dashed',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    outline: 'none',
    transition: 'border .24s ease-in-out'
  },
  previewContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  preview: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    padding: theme.spacing(.5),
    margin: theme.spacing(.5)
  },
  previewImage: {
    display: 'block',
    objectFit: 'cover',
    height: '100%',
    width: '100%'
  }
}))

const MyFileUpload = React.memo((props) => {
  const [files, setFiles] = useState({})
  const [errors, setErrors] = useState(false)
  const [addedValues, setAddedValues] = useState([]);
  const setLock = props.setLock;
  const { enqueueSnackbar } = useSnackbar();
  const onUploadSuccess = (item, index) => {
    setAddedValues(addedValues => ([...addedValues, item['@id']]));
    setFiles(files => { const nf = { ...files }; delete nf[index]; return nf; });
  }
  const onUploadError = (error, index) => {
    setFiles(files => { const nf = { ...files }; delete nf[index]; return nf; });
    setErrors(true);
    enqueueSnackbar(error['hydra:description'], { variant: 'error' })
  }
  const onChange = props.onChange;
  const value = props.value || [];
  const { upload, loading, progress, loaded } = usePostFile(onUploadSuccess, onUploadError);
  const classes = useStyle()
  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.map((f) => {
      if (!f.uid) {
        f.uid = Math.random().toString(36).substr(2, 9)
      }
      if (!loading[f.uid] && !loaded[f.uid]) {
        upload(f, f.uid);
        setLock(true);
        setFiles(files => ({ ...files, [f.uid]: f }));
      }
      return f
    })
  }, [loaded, loading, upload, setLock])

  const onSelectFile = (e) => {
    onDrop(Array.from(e.target.files));
  }

  const deleteFile = (index) => {
    const nv = clone(props.value);
    nv.splice(index, 1);
    onChange(nv);
  }

  const acceptedMimes = ["application/pdf", "application/x-pdf", "audio/*", "video/*", "image/*"]
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: acceptedMimes })
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (Object.keys(files).length === 0) {
      if (addedValues.length) {
        console.log('addedValues', addedValues);
        onChange([...value, ...addedValues]);
        setLock(false);
        setAddedValues([])
      }
      if (errors) {
        setLock(false);
        setErrors(false);
      }
    }
  }, [value, addedValues, onChange, files, setLock, errors]);

  const takePicture = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      saveToGallery: true,
      resultType: CameraResultType.Base64,
      promptLabelHeader: "Source de l'image",
      promptLabelCancel: "Annuler",
      promptLabelPhoto: "Galerie",
      promptLabelPicture: "Prendre une photo"
    });

    console.log('base64', image.base64String);
    const blob = new Blob([new Uint8Array(decode(image.base64String))], {
      type: `image/${image.format}`,

    });
    onSelectFile({
      target: {
        files: [new File([blob], (new Date()).getTime() + '.' + image.format, {
          lastModified: (new Date().getTime()),
          type: blob.type
        })]
      }
    });
    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    //var imageUrl = image.webPath;
    // Can be set to the src of an image now
    //imageElement.src = imageUrl;
  }

  return (<>
    <InputLabel>{props.label}</InputLabel>
    <aside className={classes.previewContainer}>
      {props.value?.map((media, index) => <div className={classes.preview} key={media}>
        <Media
          mediaId={media}
          key={index}
          className={classes.previewImage}
          actions={<Box px={2} py={1} ><Button variant="contained" startIcon={<DeleteForeverIcon />} color="secondary" onClick={() => { deleteFile(index) }} fullWidth>Supprimer la pièce jointe</Button></Box>}
        />
      </div>)}
      {addedValues?.map((media, index) => <div className={classes.preview} key={media}>
        <Media
          mediaId={media}
          key={index}
          className={classes.previewImage}
        />
      </div>)}
      {Object.keys(files).map((index) => {
        return (<div className={classes.preview} key={index}>
          {loading[index] ? <LinearProgress variant="determinate" value={progress[index]} /> : null}
          {files[index].type.split('/')[0] === 'image' ? <img src={URL.createObjectURL(files[index])} alt={files[index].path} className={classes.previewImage} /> : <DescriptionIcon className={classes.previewImage} color="primary" />}
        </div>)
      })}
    </aside>

    {isMobile ?
      <><label htmlFor="mobile-file-input">
        <input type="file" id="mobile-file-input" onChange={onSelectFile} multiple style={{ display: 'none' }} accept={acceptedMimes.join(',')} />
        <Button variant="contained" component="span">Ajouter une pièce jointe</Button>
      </label>
        <IconButton onClick={takePicture} title="Prendre une photo"><PhotoCameraIcon /></IconButton>
      </>
      : <div {...getRootProps()} className={classes.dropzone}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Déposez votre fichier ici ...</p> :
            <p>Glissez déposez votre fichiez ici ou cliquez ici pour sélectionner un fichier</p>
        }
      </div>
    }
  </>)

})

export default MyFileUpload;